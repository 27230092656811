import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../API";
import Loading from "../../components/loading";
import ImageSlider from "./ImageSlider";
import CopyIcon from "../../assets/icons/copy.svg";
import Date from "../../assets/icons/date.svg";
import Daily from "../../assets/icons/daily.svg";
import {
  CalendarIcon,
  ChatIcon,
  EyeIcon,
  EyeOffIcon,
  ShareIcon,
  ThumbUpIcon,
} from "@heroicons/react/outline";
import PermitionModal from "../../pages/payment/PermissionModal";
import OrdersIcon from "../../assets/icons/store/orders.svg";
import ImpressionsIcon from "../../assets/icons/store/impressions.svg";
import LikesIcon from "../../assets/icons/store/likes.svg";
import ProfitIcon from "../../assets/icons/store/profit.svg";
import SellingIcon from "../../assets/icons/store/selling.svg";
import CommentsIcon from "../../assets/icons/store/comments.svg";
import SharesIcon from "../../assets/icons/store/shares.svg";
import ProductCostIcon from "../../assets/icons/store/product-cost.svg";
import { fShortenNumber } from "../../utils/formatNumber";
import { lastseenDateCount, SeenDate } from "../../controllers/actions";
import PostItem from "../../components/postItem";
import ProductItem from "../ali-express/ProductItem";

import Facebook from "../../assets/icons/facebook.svg";
import Instagram from "../../assets/icons/instagram.svg";
import Tiktok from "../../assets/icons/tiktok.svg";
import Ali from "../../assets/icons/Aliexpress.png";
import Pinterest from "../../assets/icons/pinterest.svg";

const IconCard = ({ name, icon, count }) => {
  return (
    <div className=" bg-white flex items-center justify-evenly px-2  py-4 rounded-md border-[1px] border-[#E2E8F0] ">
      {/* <img src={icon} /> */}
      {icon}
      <div>
        <p className="text-[#383C3D] text-[28px] leading-8 font-[500] text-center">
          {count}
        </p>
        <p className="text-[#828080] text-[16px] font-[400] text-center">
          {name}
        </p>
      </div>
    </div>
  );
};
const IconCard_2 = ({ name, icon, count }) => {
  return (
    <div className=" bg-white flex items-center justify-center gap-3 px-2 w-full   py-4 rounded-md  border-[1px] border-[#E2E8F0]">
      {/* <img src={icon} /> */}
      {icon}
      <div>
        <p className="text-[#383C3D] text-[28px] leading-8 font-[500] text-center">
          {count}
        </p>
        <p className="text-[#828080] text-[16px] font-[400] text-center">
          {name}
        </p>
      </div>
    </div>
  );
};
const ProductDetails = ({ userInfo }) => {
  let { productId } = useParams();
  const { user, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState();
  const [myList, setMyList] = useState([]);
  const [posts, setPosts] = useState([]);
  // const [socialType, setSocialType] = useState('facebook');
  const [productModel, setProductModel] = useState("posts");
  const [dailyWishlist, setDailyWishlist] = useState("");
  const [votes, setVotes] = useState("");
  const [price, setPrice] = useState("");
  const [totalOrders, setTotalOrders] = useState("");
  const [profitMargin, setProfitMargin] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [productCost, setProductCost] = useState("");
  const [impressions, setImpressions] = useState("");
  const [likes, setLikes] = useState("");
  const [comments, setComments] = useState("");
  const [shares, setShares] = useState("");
  const [facebookCount, setFacebookCount] = useState("");
  const [tiktokCount, setTiktokCount] = useState("");
  const [pinterestCount, setPinterestCount] = useState("");
  const [instagramCount, setInstagramCount] = useState("");
  const [socialImpressions, setSocialImpressions] = useState("");
  const [socialLikes, setSocialLikes] = useState("");
  const [socialtComments, setSocialComments] = useState("");
  const [socialShares, setSocialShares] = useState("");
  const [playing, setPlaying] = useState(-1);
  const [aliexpressCount, setAliexpressCount] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [error, setError] = useState(false);
  const [first_seen, setFirstSeen] = useState();
  const [last_seen, setLatstSeen] = useState();
  useEffect(() => {
    (async () => {
      try {
        if (userInfo) {
          API.get(`store/${productId}?user_id=${userInfo._id}`, {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          })
            .then((res) => {
              setProduct(res.data);
              const postsRelated = res.data.productsRelated.filter(
                (v) => v.productModel === "posts"
              );
              // const views = postsRelated.reduce((acc, obj) => acc + obj.product.views, 0);
              const views = postsRelated.reduce((acc, obj) => {
                if (
                  obj.product?.video_views == 0 ||
                  obj.product?.video_views == undefined
                ) {
                  return acc + obj.product?.like * 220;
                } else {
                  return acc + obj.product?.video_views;
                }
              }, 0);
              let date = res.data.postProduct;
              const seen = date.reduce((newest, current) => {
                return current.last_seen > newest.last_seen ? current : newest;
              }, date[0]);
              setLatstSeen(seen?.last_seen);
              const createdAt = date.reduce((newest, current) => {
                return current.post_create < newest.post_create
                  ? current
                  : newest;
              }, date[0]);
              setFirstSeen(createdAt?.post_create);
              //   setImpressions(views)
              setImpressions(views);
              const likes = postsRelated.reduce(
                (acc, obj) => acc + obj.product.like,
                0
              );
              setLikes(likes);
              const comments = postsRelated.reduce(
                (acc, obj) => acc + obj.product.comment,
                0
              );
              setComments(comments);
              const shares = postsRelated.reduce(
                (acc, obj) => acc + obj.product.share,
                0
              );
              setShares(shares);

              const facebookPosts = postsRelated.filter(
                (v) =>
                  ![
                    "tiktok",
                    "instagram",
                    "pinterest",
                    "facebook_library",
                  ].includes(v.product.social_type)
              );
              setFacebookCount(facebookPosts.length);
              socialCountFilter(facebookPosts);

              const instagramPosts = postsRelated.filter(
                (v) => v.product.social_type === "instagram"
              );
              setInstagramCount(instagramPosts.length);
              const tiktokPosts = postsRelated.filter(
                (v) => v.product.social_type === "tiktok"
              );
              setTiktokCount(tiktokPosts.length);
              const pinterestPosts = postsRelated.filter(
                (v) => v.product.social_type === "pinterest"
              );
              setPinterestCount(pinterestPosts.length);

              const aliexpressRelated = res.data.productsRelated.filter(
                (v) => v.productModel === "aliexpress"
              );
              const totalOrders = aliexpressRelated.reduce(
                (acc, obj) => acc + obj.product.totalOrders,
                0
              );
              setTotalOrders(totalOrders);
              const shippingCostAvg =
                aliexpressRelated.reduce(
                  (acc, obj) => acc + obj.product.shippingCost,
                  0
                ) / aliexpressRelated.length;
              setProductCost(shippingCostAvg);
              const priceAvg =
                aliexpressRelated.reduce(
                  (acc, obj) => acc + obj.product.originalPrice.value,
                  0
                ) / aliexpressRelated.length;
              setSellingPrice(priceAvg);
              // const wishlistCount = aliexpressRelated.reduce((acc, obj) => acc + obj.product.wishlistCount, 0);
              // setTotalWishlist(wishlistCount)
              // const averageRating = aliexpressRelated.reduce((acc, obj) => acc + obj.product.reviewsRatings.averageRating, 0);
              // setReviews(averageRating)
              // const totalCount = aliexpressRelated.reduce((acc, obj) => acc + obj.product.reviewsRatings.totalCount, 0);
              // setReviews(totalCount)
              setAliexpressCount(aliexpressRelated.length);
              setPosts(facebookPosts);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err, "err");
              if (err?.response?.status === 429) {
                setViewModal(true);
                setLoading(false);
                setError(true);
              }
            });
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [productId]);
  // useEffect(() => {

  //     return () => { };
  // }, [socialType, productModel]);
  useEffect(() => {
    (async () => {
      try {
        API.get(`favorites?email=${user.email}`, {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }).then((res) => {
          setMyList([]);
          res.data.forEach((element) => {
            setMyList((prev) => [...prev, element.post?._id]);
          });
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  const socialCountFilter = (socialPost) => {
    // const socialviews = socialPost.reduce((acc, obj) => acc + obj.product.views, 0);
    const socialviews = socialPost.reduce((acc, obj) => {
      if (
        obj.product?.video_views == 0 ||
        obj.product?.video_views == undefined
      ) {
        return acc + obj.product?.like * 220;
      } else {
        return acc + obj.product?.video_views;
      }
    }, 0);
    setSocialImpressions(socialviews);
    const sociallikes = socialPost.reduce(
      (acc, obj) => acc + obj.product.like,
      0
    );
    setSocialLikes(sociallikes);
    const socialcomments = socialPost.reduce(
      (acc, obj) => acc + obj.product.comment,
      0
    );
    setSocialComments(socialcomments);
    const socialshares = socialPost.reduce(
      (acc, obj) => acc + obj.product.share,
      0
    );
    setSocialShares(socialshares);
  };
  const postFilter = (_productModel, _socialType) => {
    if (product?.productsRelated) {
      // alert(productModel)
      // alert(socialType)
      setLoading(true);
      setPosts([]);
      setProductModel(_productModel);
      const postsRelated = product?.productsRelated?.filter(
        (v) => v.productModel === _productModel
      );
      if (_productModel === "posts") {
        if (_socialType === "facebook") {
          const facebookPosts = postsRelated.filter(
            (v) =>
              ![
                "tiktok",
                "instagram",
                "pinterest",
                "facebook_library",
              ].includes(v.product.social_type)
          );
          socialCountFilter(facebookPosts);
          setPosts([...facebookPosts]);
          // alert(JSON.stringify(facebookPosts))
        } else {
          const socialTypePosts = postsRelated.filter(
            (v) => v.product.social_type === _socialType
          );
          socialCountFilter(socialTypePosts);
          setPosts([...socialTypePosts]);
          // alert(JSON.stringify(socialTypePosts))
        }
      } else if (_productModel === "aliexpress") {
        setPosts(postsRelated);
      }
      setLoading(false);
    }
  };
  const findAliexpress = () => {
    // let _url = `https://www.aliexpress.com/w/wholesale-${title}.html?spm=a2g0o.productlist.search.0`

    let url = product.aliexpressProduct[0]?.productUrl;
    if (url) {
      window.open(url, "_blank");
    }
  };
  if (loading) {
    return (
      <div className="py-20">
        <Loading />
      </div>
    );
  }
  if (viewModal) {
    return (
      <div className="h-[80vw]">
        <PermitionModal
          message="You have reached the maximum number of post products for today"
          viewModal={viewModal}
          setViewModal={() => {
            setViewModal(false);
          }}
        />
      </div>
    );
  }
  if (error) {
    return (
      <div className="h-[80vh] relative w-full">
        <div className="absolute inset-1/2 ">Not_Found</div>
      </div>
    );
  }
  return (
    <div
      className="container  mx-auto max-w-8xl lg:px-20 pt-6 text-[#191C24]"
      onMouseEnter={() => setPlaying(-1)}
    >
      <div className="grid grid-cols-12 gap-6">
        <div className="lg:col-span-4 col-span-12 bg-white overflow-hidden rounded-md  border-[#E2E8F0] border-[1px]">
          <ImageSlider images={product?.productImage} />
        </div>
        <div className="lg:col-span-8 col-span-12 flex flex-col justify-between">
          <div className="flex items-center justify-between lg:flex-row flex-col  w-full  rounded-md py-5 px-5">
            <div className="lg:w-9/12 w-full">
              <div className="flex items-center">
                <p
                  className="text-[#5E5E5E] text-[24px]  font-[700] line-clamp-1 break-all capitalize mix-blend-normal"
                  //  onClick={() => navigator.clipboard.writeText(product?.title)}
                >
                  {product?.title}
                </p>
                {/* <img src={CopyIcon} className='cursor-pointer mr-4' style={{ width: 28, height: 28 }} onClick={() => navigator.clipboard.writeText(product?.title)} /> */}
              </div>
              <div className="flex items-center gap-4 mt-2">
                <div className="flex items-center gap-2">
                  {/* <img src={Date} /> */}
                  <CalendarIcon className=" w-7" color="#6B7677" />
                  <p className="text-lg text-[#6B7677] ">
                    Created {lastseenDateCount(first_seen)}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  {/* <img src={Daily} /> */}
                  <EyeIcon className=" w-7" color="#6B7677" />
                  <p className="text-lg text-[#6B7677] ">
                    Last seen: {lastseenDateCount(last_seen)}
                  </p>
                </div>
              </div>
            </div>
            {/* <button className='text-lg p-4     bg-[#ff6c3d] hover:opacity-90 text-white rounded-lg'>Add to Store</button> */}
          </div>

          <div className=" grid grid-cols-4 rounded-md gap-3  p-6 ">
            <IconCard
              name={"Orders"}
              count={fShortenNumber(totalOrders)}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-shopping-cart-check"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#ff6c3d"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M4 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                  <path d="M11.5 17h-5.5v-14h-2" />
                  <path d="M6 5l14 1l-1 7h-13" />
                  <path d="M15 19l2 2l4 -4" />
                </svg>
              }
            />
            <IconCard
              name={"Product cost"}
              count={"$" + fShortenNumber(productCost)}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-brand-shopee"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#ff6c3d"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M4 7l.867 12.143a2 2 0 0 0 2 1.857h10.276a2 2 0 0 0 2 -1.857l.867 -12.143h-16z" />
                  <path d="M8.5 7c0 -1.653 1.5 -4 3.5 -4s3.5 2.347 3.5 4" />
                  <path d="M9.5 17c.413 .462 1 1 2.5 1s2.5 -.897 2.5 -2s-1 -1.5 -2.5 -2s-2 -1.47 -2 -2c0 -1.104 1 -2 2 -2s1.5 0 2.5 1" />
                </svg>
              }
            />
            <IconCard
              name={"Selling price"}
              count={"$" + fShortenNumber(sellingPrice)}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-coin"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#ff6c3d"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                  <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 1 0 0 4h2a2 2 0 1 1 0 4h-2a2 2 0 0 1 -1.8 -1" />
                  <path d="M12 7v10" />
                </svg>
              }
            />
            <IconCard
              name={"Profit margin"}
              count={"$" + fShortenNumber(sellingPrice - productCost)}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-discount-2"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#ff6c3d"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 15l6 -6" />
                  <circle cx="9.5" cy="9.5" r=".5" fill="currentColor" />
                  <circle cx="14.5" cy="14.5" r=".5" fill="currentColor" />
                  <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" />
                </svg>
              }
            />
            <IconCard
              name={"impressions"}
              count={fShortenNumber(impressions)}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-hand-click"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#ff6c3d"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5" />
                  <path d="M11 11.5v-2a1.5 1.5 0 0 1 3 0v2.5" />
                  <path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5" />
                  <path d="M17 11.5a1.5 1.5 0 0 1 3 0v4.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7l-.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47" />
                  <path d="M5 3l-1 -1" />
                  <path d="M4 7h-1" />
                  <path d="M14 3l1 -1" />
                  <path d="M15 6h1" />
                </svg>
              }
            />
            <IconCard
              name={"Likes"}
              count={fShortenNumber(likes)}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-thumb-up"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#ff6c3d"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" />
                </svg>
              }
            />
            <IconCard
              name={"Comments"}
              count={fShortenNumber(comments)}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-brand-wechat"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#ff6c3d"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M16.5 10c3.038 0 5.5 2.015 5.5 4.5c0 1.397 -.778 2.645 -2 3.47l0 2.03l-1.964 -1.178a6.649 6.649 0 0 1 -1.536 .178c-3.038 0 -5.5 -2.015 -5.5 -4.5s2.462 -4.5 5.5 -4.5z" />
                  <path d="M11.197 15.698c-.69 .196 -1.43 .302 -2.197 .302a8.008 8.008 0 0 1 -2.612 -.432l-2.388 1.432v-2.801c-1.237 -1.082 -2 -2.564 -2 -4.199c0 -3.314 3.134 -6 7 -6c3.782 0 6.863 2.57 7 5.785l0 .233" />
                  <path d="M10 8h.01" />
                  <path d="M7 8h.01" />
                  <path d="M15 14h.01" />
                  <path d="M18 14h.01" />
                </svg>
              }
            />
            <IconCard
              name={"Shares"}
              count={fShortenNumber(shares)}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-share"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#ff6c3d"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                  <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                  <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                  <path d="M8.7 10.7l6.6 -3.4" />
                  <path d="M8.7 13.3l6.6 3.4" />
                </svg>
              }
            />
          </div>
          <div className="   w-full grid grid-cols-4 rounded-md gap-3  p-6">
            <div className=" ">
              <a href={product?.landingPage} target={"_blank"}>
                <button className="w-full py-3 text-[#818181] hover:bg-gray-200 text-[16px] font-[400] text-center rounded-lg border ">
                  Landing Page
                </button>
              </a>
            </div>
            <div className="">
              <button
                className="w-full py-3 text-[#818181] text-[16px] hover:bg-gray-200 font-[400] text-center rounded-lg border "
                onClick={() => {
                  findAliexpress();
                }}
                disabled={product.aliexpressProduct[0]?.productUrl ? false : true}
              >
                Find on Aliexpress
              </button>
            </div>
            <div className="">
              <button
                className="w-full py-3 text-[#818181] text-[16px] hover:bg-gray-200 font-[400] text-center rounded-lg border "
                onClick={() => {
                  window.open(
                    `https://www.amazon.com/s?k=${product?.title}`,
                    "_blank"
                  );
                }}
              >
                Find on Amazon
              </button>
            </div>
            <div className="">
              <button
                className="w-full py-3 text-[#818181] text-[16px] hover:bg-gray-200 font-[400] text-center rounded-lg border "
                onClick={() => {
                  window.open(
                    `https://www.google.com/search?q=site%3Amyshopify.com ${product?.title}`,
                    "_blank"
                  );
                }}
              >
                Find On Shopify
              </button>
            </div>
          </div>
        </div>
      </div>
      <AdsPlatform
        data={platforms}
        product={product}
        postFilter={postFilter}
        setProductModel={setProductModel}
        productModel={productModel}
      />

      {productModel === "posts" && (
        <div className="w-full  py-6  flex justify-between gap-3">
          <IconCard_2
            name={"Impressions"}
            count={fShortenNumber(socialImpressions)}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-hand-click"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ff6c3d"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5" />
                <path d="M11 11.5v-2a1.5 1.5 0 0 1 3 0v2.5" />
                <path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5" />
                <path d="M17 11.5a1.5 1.5 0 0 1 3 0v4.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7l-.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47" />
                <path d="M5 3l-1 -1" />
                <path d="M4 7h-1" />
                <path d="M14 3l1 -1" />
                <path d="M15 6h1" />
              </svg>
            }
          />
          <IconCard_2
            name={"Likes"}
            count={fShortenNumber(socialLikes)}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-thumb-up"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ff6c3d"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" />
              </svg>
            }
          />
          <IconCard_2
            name={"Comments"}
            count={fShortenNumber(socialtComments)}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-brand-wechat"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ff6c3d"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M16.5 10c3.038 0 5.5 2.015 5.5 4.5c0 1.397 -.778 2.645 -2 3.47l0 2.03l-1.964 -1.178a6.649 6.649 0 0 1 -1.536 .178c-3.038 0 -5.5 -2.015 -5.5 -4.5s2.462 -4.5 5.5 -4.5z" />
                <path d="M11.197 15.698c-.69 .196 -1.43 .302 -2.197 .302a8.008 8.008 0 0 1 -2.612 -.432l-2.388 1.432v-2.801c-1.237 -1.082 -2 -2.564 -2 -4.199c0 -3.314 3.134 -6 7 -6c3.782 0 6.863 2.57 7 5.785l0 .233" />
                <path d="M10 8h.01" />
                <path d="M7 8h.01" />
                <path d="M15 14h.01" />
                <path d="M18 14h.01" />
              </svg>
            }
          />
          <IconCard_2
            name={"Shares"}
            count={fShortenNumber(socialShares)}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-share"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ff6c3d"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                <path d="M8.7 10.7l6.6 -3.4" />
                <path d="M8.7 13.3l6.6 3.4" />
              </svg>
            }
          />
        </div>
      )}

      <ul
        id="header"
        className={`grid grid-cols-1 mt-5 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-10`}
        onMouseEnter={() => setPlaying(-1)}
      >
        {posts.length > 0 && productModel === "posts"
          ? posts?.map((post, index, posts) => (
              <li
                key={index}
                className="col-span-1 bg-white rounded-[8px] shadow w-full"
              >
                <PostItem
                  post={post.product}
                  user={user}
                  posts={posts}
                  myList={myList}
                  setMyList={setMyList}
                  store={true}
                  onDeleteSuccess={() => {
                    let updatedProducts = product;
                    updatedProducts.productsRelated =
                      updatedProducts.productsRelated.filter(
                        (v) => v.product._id !== post.product._id
                      );
                    setProduct(updatedProducts);
                    const updatedPosts = posts.filter(
                      (v) => v.product._id !== post.product._id
                    );
                    setPosts([...updatedPosts]);
                  }}
                  onMouseEnter={() => setPlaying(index)}
                  onMouseLeave={() => setPlaying(-1)}
                  playing={playing}
                  index={index}
                  userInfo={userInfo}
                />
              </li>
            ))
          : posts.length > 0 &&
            productModel === "aliexpress" &&
            posts?.map((post, index, posts) => (
              <li
                key={index}
                className="col-span-1  rounded-lg border-[1px] border-[#E2E8F0] w-full "
              >
                <ProductItem
                  product={post.product}
                  user={user}
                  store={true}
                  onDeleteSuccess={() => {
                    let updatedProducts = product;
                    updatedProducts.productsRelated =
                      updatedProducts.productsRelated.filter(
                        (v) => v.product._id !== post.product._id
                      );
                    setProduct(updatedProducts);
                    const updatedPosts = posts.filter(
                      (v) => v.product._id !== post.product._id
                    );
                    setPosts([...updatedPosts]);
                  }}
                />
              </li>
            ))}
      </ul>
    </div>
  );
};
const platforms = [
  { name: "Facebook Ads", value: "facebook", src: Facebook, post: "posts" },
  { name: "Instagram Ads", value: "instagram", src: Instagram, post: "posts" },
  { name: "Pinterest Ads", value: "pinterest", src: Pinterest, post: "posts" },
  { name: "Tiktok Ads", value: "tiktok", src: Tiktok, post: "posts" },
  { name: "Aliexpress", value: "", src: Ali, post: "aliexpress" },
];

export function AdsPlatform({
  data,
  postFilter,
  productModel,
  setProductModel,
  product,
}) {
  const [selectd, setSelectd] = useState("Facebook Ads");

  return (
    <div className=" border-b-[0.5px] border-[#E2E8F0] my-5">
      <div className=" max-w-8xl mx-auto  px-4 sm:px-6 lg:px-10 ">
        <div className=" flex gap-5 justify-center flex-wrap font-semibold text-[18px] pt-2.5 text-[#6B7280]  ">
          {data.map((item) => {
            return (
              <button
                key={item.value}
                className={`flex items-center justify-center p-2  disabled:text-[#6B7280] disabled:border-[#6B7280] hover:pb-[6px] hover:border-b-[2.5px]  hover:text-[#4267B2] hover:border-[#4267B2] ${
                  selectd === item.name
                    ? "border-b-[2.5px] text-[#4267B2] border-[#4267B2] pb-[6px]"
                    : ""
                }`}
                onClick={() => {
                  postFilter(item.post, item.value);
                  if (item.name == "aliexpress") {
                    setProductModel("aliexpress");
                  }
                  setSelectd(item.name);
                }}
              >
                <img
                  className={`${selectd !== item.name ? "back-and-white" : ""}`}
                  src={item.src}
                  width={18}
                  alt={item.name}
                />
                <span className={`ml-3`}>
                  {item.name} (
                  {item.post === "aliexpress"
                    ? product.aliexpressProduct.length
                    : product?.postProduct?.filter(
                        (v) => v.social_type === item.value
                      )?.length || "0"}
                  ){" "}
                </span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default ProductDetails;
