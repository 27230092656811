import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import API from "../../API";
import { useAuth0 } from "@auth0/auth0-react";

export default function EditPost({
  setOpenEdit,
  openEdit,
  editedpost,
  setPosts,
  posts,
}) {
  const { user, getAccessTokenSilently } = useAuth0();
  const [Editedpost, setEditedPost] = useState(editedpost);
  const [Editedfiled, setEditedFiled] = useState();
  const s3_bucket = editedpost?.social_type !== undefined ? `https://aas-bucket.s3.amazonaws.com` : 'https://aas-bucket.s3.amazonaws.com/uploads'
  const handelChange = (e) => {
    e.preventDefault();
    setEditedPost({ ...Editedpost, [e.target.name]: e.target.value });
    setEditedFiled({ ...Editedfiled, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    setEditedPost(editedpost);
  }, [editedpost]);

  const handelSubmit = async() => {
    try {
      API.patch(
        `posts/${editedpost._id}?role=${JSON.stringify(user?.role)}`, {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        },
        Editedfiled
      ).then(() => {
        posts[posts.findIndex((e) => e._id === editedpost._id)] = Editedpost;
        setPosts(posts);
        API.post("logs", {
          user: user,
          post: editedpost,
          modify: Editedfiled,
          action: "edit",
          date: new Date(),
        });

        setOpenEdit(false);
      });
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect(() => {
  //   console.log(Editedfiled);
  // }, [Editedfiled]);

  return (
    <Transition.Root show={openEdit} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpenEdit}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-7xl sm:w-full">
                <button
                  type="button"
                  className="absolute text-white top-5 right-5 p-1 rounded-full bg-black bg-opacity-50 z-50 hover:bg-opacity-80"
                  onClick={() => setOpenEdit(false)}
                >
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>

                <form className="bg-white mx-8 px-4 pt-5 pb-4 sm:p-6 sm:pb-4 grid gap-4 gap-x-6 md:grid-cols-2">
                  <div className="flex gap-8 justify-center">
                    <img
                      className="w-20 h-20 border "
                      src={`${s3_bucket}/${Editedpost?.resource.page_logo}`}
                      // src={`https://aas-bucket.s3.amazonaws.com/uploads/${Editedpost?.resource.page_logo}`}
                      alt=""
                    />
                    <img
                      className="w-32 h-20"
                      src={`${s3_bucket}/${Editedpost?.resource.image}`}
                      // src={`https://aas-bucket.s3.amazonaws.com/uploads/${Editedpost?.resource.image}`}
                      alt=""
                    />
                    {Editedpost.resource.video && (
                      <video className="w-32 h-20  " alt="" controls>
                        <source
                          src={editedpost?.social_type !== undefined ? `${s3_bucket}/${Editedpost?.resource.video}` : `${s3_bucket}${Editedpost?.resource.video}`}
                          // src={`https://aas-bucket.s3.amazonaws.com/uploads${Editedpost?.resource.video}`}
                          type="video/mp4"
                        />
                      </video>
                    )}
                  </div>

                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="Page id"
                    >
                      Page id :
                    </label>
                    <input
                      value={Editedpost?.page_id || ""}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="page_id"
                      onChange={handelChange}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="Post id"
                    >
                      Post id :
                    </label>
                    <input
                      value={Editedpost?.post_id || ""}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="post_id"
                      onChange={handelChange}
                    />
                  </div>

                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="Page name"
                    >
                      Page name :
                    </label>
                    <input
                      value={Editedpost?.page_name || ""}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="page_name"
                      onChange={handelChange}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="Post link"
                    >
                      Post link :
                    </label>
                    <input
                      value={Editedpost?.post_link || ""}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="post_link"
                      onChange={handelChange}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="Page link"
                    >
                      Page link :
                    </label>
                    <input
                      value={Editedpost?.page_link || ""}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="page_link"
                      onChange={handelChange}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="Country"
                    >
                      Country :
                    </label>
                    <input
                      value={Editedpost?.country || ""}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="country"
                      onChange={handelChange}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="objective"
                    >
                      Objective :
                    </label>
                    <input
                      value={Editedpost?.button || ""}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="button"
                      onChange={handelChange}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="Country see In"
                    >
                      Country see In :
                    </label>
                    <input
                      value={Editedpost?.country_see_in || ""}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="country_see_in"
                      onChange={handelChange}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="Language"
                    >
                      Language :
                    </label>
                    <input
                      value={Editedpost?.lang || ""}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="lang"
                      onChange={handelChange}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="Category"
                    >
                      Category :
                    </label>
                    <select
                      type="checkbox"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      name="category"
                      onChange={handelChange}
                      defaultValue=""
                    >
                      <option hidden>{Editedpost.category || ""}</option>
                      <option value={JSON.stringify(["Bags&Shoes"])}>
                        Bags Shoes
                      </option>
                      <option value={JSON.stringify(["Jewelry&Watches"])}>
                        Jewelry Watches
                      </option>
                      <option value={JSON.stringify(["Vehicles"])}>
                        Vehicles
                      </option>
                      <option value={JSON.stringify(["Fashion"])}>
                        Fashion
                      </option>
                      <option value={JSON.stringify(["Beauty"])}>Beauty</option>
                      <option value={JSON.stringify(["Events"])}>Events</option>
                      <option value={JSON.stringify(["Phone&Electronics"])}>
                        Phone Electronics
                      </option>
                      <option value={JSON.stringify(["Business&Finance"])}>
                        Business Finance
                      </option>
                      <option value={JSON.stringify(["Home&Garden"])}>
                        Home Garden
                      </option>
                      <option value={JSON.stringify(["Health&Fitness"])}>
                        Health Fitness
                      </option>
                    </select>
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="Gender"
                    >
                      Gender :
                    </label>
                    <input
                      value={Editedpost?.gender || ""}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="gender"
                      onChange={handelChange}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="Interested"
                    >
                      Interested :
                    </label>
                    <input
                      value={Editedpost?.interested || ""}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="interested"
                      onChange={handelChange}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="Age"
                    >
                      Age :
                    </label>
                    <input
                      value={Editedpost?.age || ""}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="age"
                      onChange={handelChange}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="Title Post"
                    >
                      Post Title :
                    </label>
                    <textarea
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="title"
                      onChange={handelChange}
                      value={Editedpost?.title || ""}
                    ></textarea>
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="Description"
                    >
                      Post Description :
                    </label>
                    <textarea
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="Ad_Description"
                      onChange={handelChange}
                      value={Editedpost?.Ad_Description || ""}
                    ></textarea>
                  </div>
                </form>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    onClick={handelSubmit}
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpenEdit(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
