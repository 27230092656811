import React, { useState, useEffect } from "react";
import PopUp from "../Pop-up";
import API from "../../API";
import EditPost from "./edit-post";
import { useAuth0 } from "@auth0/auth0-react";
import Filter from "../Filter";
import Loading from "../loading";
import { dateFormat } from "../../controllers/actions";

// import Pagination from "@mui/material/Pagination";
export default function PostTable({ Search }) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editedPost, setEditPost] = useState();
  const [deleteID, setDeleteID] = useState([]);
  const [post, setPost] = useState();
  const { user, getAccessTokenSilently } = useAuth0();
  const [posts, setPosts] = useState([]);
  const [clear, setclear] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noResult, setnoResult] = useState(false);
  const [skip, setskip] = useState(0);
  const [selected, setSelected] = useState();
  const [reset, setReset] = useState({});
  const [social, setSocial] = useState('facebook')
  const [data, setData] = useState({ skip: 0, social_type: social,"search_type":"ad_description" });
  const [deletList, setdeletList] = useState([]);
  const [todayCount, settodayCount] = useState(0);
  const [todayCreated, settodayCreated] = useState(0);

  const handleFilter = (event) => {
    try {
      // setPosts([]);
      setnoResult(false);
      let name = event.target.name;
      let value = event.target.value;
      if (event.target.reaction) {
        name = "reaction";
        value = {
          name: event.target?.reaction.value,
          min: Number(event.target?.min.value) || 0,
        };
      }
      setData({ ...data, [name]: value });
      setReset({ ...reset, [name]: value });
      event.preventDefault();
    } catch (error) {
      console.log(error);
    }
  };
  const handleCalander = (start, end) => {
    try {
      // setPosts([]);
      setnoResult(false);
      setData((data) => ({
        ...data,
        calander: {
          start: start,
          end: end,
        },
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selected?.name) {
      // setPosts([]);
      setnoResult(false);
      setData((data) => ({
        ...data,
        [selected?.name]: selected?.value,
      }));
    }
  }, [selected]);
  const hundalePrev = (e) => {
    try {
      e.preventDefault();
      setskip(skip - 1);
      setData((data) => ({
        ...data,
        skip: skip - 1,
      }));
    } catch (error) {
      console.log(error);
    }
  };
  const hundaleNext = (e) => {
    try {
      e.preventDefault();
      setskip(skip + 1);
      setData((data) => ({ ...data, skip: skip + 1 }));
    } catch (error) {
      console.log(error);
    }
  };
  const resetFilter = () => {
    try {
      setdeletList([]);
      setclear(true);
      setData({ skip: 0, social_type: social ,"search_type":"ad_description" });
      setskip(0);
      document.getElementById("search").value = "";
      document.getElementById("min").value = "";
      document.getElementById("reaction").value = "like";
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (Search) {
      setnoResult(false);
      setData((data) => ({ ...data, search: Search }));
    }
  }, [Search]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setPosts([]);
        API.get(
          `posts/admin?data=${JSON.stringify(data)}&role=${JSON.stringify(
            user.role[0]
            // "admin"
          )}`,
          {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          }
        ).then((res) => {
          setPosts(res.data);
          setLoading(false);
          if (res.data?.length === 0) {
            setnoResult(true);
          }
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [data]);
  useEffect(() => {
    (async () => {
      try {
        API.get(`posts/total?role=${JSON.stringify(user?.role)}&data=${JSON.stringify(data)}`, {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }).then((res) => {
          settodayCount(res.data.todayLastSeen);
          settodayCreated(res.data.todayCreated)
          // let n = 0;
          // res.data.forEach((element) => {
          //   if (
          //     new Date(element.created_at).toLocaleDateString("en-CA") ===
          //     new Date().toLocaleDateString("en-CA")
          //   ) {
          //     n++;
          //   }
          // });
          // settodayCreated(n);
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [social]);
  const Confirm = async (a) => {
    try {
      API.delete(
        `posts?delete=${JSON.stringify(deleteID)}&role=${JSON.stringify(
          user?.role
        )}`,
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }
      ).then(() => {
        API.post("logs", {
          user: user,
          post: post,
          action: "delete",
          date: new Date(),
        });
        setPosts(posts.filter((item) => !deleteID.includes(item._id)));
        setOpen(false);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const Category = async (id, cat) => {
    try {
      API.patch(
        `posts/${id}?role=${JSON.stringify(user?.role)}`,
        {
          category: JSON.stringify([cat]),
        },
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }
      ).then(() => {
        alert("Change Successfully");
      });
    } catch (error) {
      console.log(error);
    }
  };
  const deletePost = (id, data) => {
    try {
      setOpen(true);
      setDeleteID(id);
      setPost(data);
    } catch (error) {
      console.log(error);
    }
  };
  const editPost = (post) => {
    try {
      setOpenEdit(true);
      setEditPost(post);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSocial = (name) => {
    if (social !== name) {
      setPosts([])
      setSocial(name)
      setnoResult(false);
      setData((prev) => ({ ...prev, skip: 0, social_type: name }))
      setskip(0);
    }
  };
  return (
    <main
      className=" relative"
      style={{ height: "calc(100% - 112px)" }}
    >
      <div className="h-full flex flex-col max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Filter
          handleFilter={handleFilter}
          resetFilter={resetFilter}
          reset={reset}
          setSelected={setSelected}
          handleCalander={handleCalander}
          data={data}
          setclear={setclear}
          clear={clear}
          social={social}
          handleSocial={handleSocial}
        />
        <div className="flex h-full my-3 justify-between gap-4">
          <button
            disabled={!(deletList.length > 0)}
            onClick={() => deletePost(deletList, deletList)}
            className="bg-red-500 hover:bg-red-700 text-white  py-2 px-4 border border-red-700 rounded disabled:opacity-75"
          >
            Delete Many <small>N° {deletList.length}</small>
          </button>
          <div className="flex justify-between gap-6">
            <h2 className="py-1 text-gray-500">
              Page Number : <span className="font-bold">{skip + 1}</span>{" "}
            </h2>
            <h2 className="py-1 text-gray-500">
              Today seen ads N°: <span className="font-bold">{todayCount}</span>{" "}
            </h2>
            <h2 className="py-1 text-gray-500">
              Today created ads N°: <span className="font-bold">{todayCreated}</span>{" "}
            </h2>
          </div>
          <div className="flex gap-4">
            <button
              className="rounded-md bg-sky-100 px-2 py-1 text-sky-400 border-sky-400 border-2 "
              onClick={hundalePrev}
              disabled={skip === 0}
            >
              Prev
            </button>
            <button
              className="rounded-md bg-sky-100 px-2 py-1 text-sky-400 border-sky-400 border-2"
              onClick={hundaleNext}
            >
              Next
            </button>
          </div>
        </div>
        <div className=" w-7xl">
          {posts && (
            <table className="w-full border-collapse border text-[10px] md:text-xs  lg:text-sm text-gray-500 ">
              <thead className="bg-white font-bold">
                <tr>
                  <th scope="col" className="py-3 pl-4 w-10">
                    #
                  </th>
                  <th
                    scope="col"
                    className="p-2 md:px-6 md:py-3  text-left  uppercase md:w-[100px]  "
                  >
                    logo
                  </th>
                  <th
                    scope="col"
                    className="p-2 md:px-6 md:py-3 hidden md:table-cell text-left  uppercase md:w-[100px]"
                  >
                    Image
                  </th>
                  {/* <th
                    scope="col"
                    className="p-2 md:px-6 md:py-3 hidden  md:table-cell text-left uppercase md:w-[180px] "
                  >
                    Page name
                  </th> */}
                  <th
                    scope="col"
                    className="p-2 md:px-6 md:py-3  text-left  uppercase md:w-[140px]"
                  >
                    Category
                  </th>
                  <th className="p-2 md:px-6 md:py-3   w-20 text-left  uppercase md:w-[240px]">
                    <div className="flex items-center ">
                      <input
                        onChange={() => {
                          setTitle(!title);
                          setnoResult(false);
                          setData((data) => ({ ...data, title: !title }));
                        }}
                        checked={title}
                        type="checkbox"
                        className="text-orange-500 border-gray-200 rounded focus:ring-orange-500"
                      />
                      <label htmlFor="checkbox" className="sr-only">
                        Checkbox
                      </label>
                      <span className="mx-2">title</span>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="p-2 md:px-6 md:py-3  text-left  uppercase md:w-[120px]"
                  >
                    format
                  </th>
                  <th
                    scope="col"
                    className="p-2 md:px-6 md:py-3  text-left  uppercase md:w-[120px]"
                  >
                    last_seen
                  </th>
                  <th
                    scope="col"
                    className="p-2 md:px-6 md:py-3   text-left md:pr-0 uppercase md:w-[140px]"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {posts.map((post) => (
                  <tr key={post._id} className="font-medium border-b">
                    <td className="py-3 pl-4">
                      <div className="flex items-center h-8">
                        <input
                          onChange={() => {
                            if (deletList.includes(post._id)) {
                              setdeletList((data) =>
                                data.filter((item) => item !== post._id)
                              );
                            } else {
                              setdeletList((data) => [...data, post._id]);
                            }
                          }}
                          checked={deletList.includes(post._id)}
                          type="checkbox"
                          className="text-orange-500 border-gray-200 rounded focus:ring-orange-500"
                        />
                        <label htmlFor="checkbox" className="sr-only">
                          Checkbox
                        </label>
                      </div>
                    </td>
                    <td className=" ">
                      <img
                        className="m-auto  "
                        // src={`https://aas-bucket.s3.amazonaws.com/uploads/${post.resource.page_logo}`}
                        src={post.resource.page_logo.indexOf('/') > -1 ? `https://aas-bucket.s3.amazonaws.com/${post.resource.page_logo}` : `https://aas-bucket.s3.amazonaws.com/uploads/${post.resource.page_logo}`}
                        alt=""
                      />
                    </td>
                    <td className=" hidden md:table-cell ">
                      <img
                        className=" max-h-20 m-auto "
                        // src={`https://aas-bucket.s3.amazonaws.com/uploads/${post.resource.image}`}
                        src={post.resource.page_logo.indexOf('/') > -1 ? `https://aas-bucket.s3.amazonaws.com/${post.resource.image}` : `https://aas-bucket.s3.amazonaws.com/uploads/${post.resource.image}`}
                        // src={`https://adsteak.s3.us-east-2.amazonaws.com/${post.resource.image}`}
                        alt=""
                      />
                    </td>
                    {/* <td className="p-2 md:px-6 md:py-4 hidden md:table-cell">
                      {post?.page_name}
                    </td> */}
                    <td className="p-2 md:px-6 md:py-4  grid gap-1   ">
                      <select
                        onChange={(e) => {
                          Category(post?._id, e.target.value);
                        }}
                        className="form-select appearance-none block w-32 min-w-full px-2 py-1.5 text-base font-normal text-gray-700 border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-orange-500 focus:outline-none"
                        defaultValue={JSON.parse(post?.category).map(
                          (element) => {
                            return element.toLowerCase();
                          }
                        )}
                        multiple
                      >
                        <option value="bags&amp;shoes">Bags&amp;Shoes</option>
                        <option value="jewelry&amp;watches">
                          Jewelry&amp;Watches
                        </option>
                        <option value="vehicles">Vehicles</option>
                        <option value="fashion">Fashion</option>
                        <option value="beauty">Beauty</option>
                        <option value="events">Events</option>
                        <option value="phone&amp;electronics ">
                          Phone&amp;Electronics
                        </option>
                        <option value="business&amp;finance">
                          Business&amp;Finance
                        </option>
                        <option value="home&amp;garden">Home&amp;Garden</option>
                        <option value="fealth&amp;fitness">
                          Health&amp;Fitness
                        </option>
                      </select>
                    </td>
                    <td className="p-2 md:px-6 md:py-4  ">{post?.title}</td>
                    <td className="p-2 md:px-6 md:py-4 ">{post?.ad_format}</td>
                    <td className="p-2 md:px-6 md:py-4  ">
                      {dateFormat(post?.last_seen)}
                    </td>
                    <td className="p-2 md:px-6 md:py-4 m-auto font-medium  flex pr-0  gap-1">
                      <button
                        onClick={() => editPost(post)}
                        className="bg-green-500 hover:bg-green-700 text-white  py-2 px-4 border border-green-700 rounded"
                      >
                        edit
                      </button>
                      <button
                        onClick={() => deletePost([post._id], post)}
                        className="bg-red-500 hover:bg-red-700 text-white  py-2 px-4 border border-red-700 rounded"
                      >
                        delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {loading && !noResult && <Loading />}
          {editedPost && (
            <EditPost
              setOpenEdit={setOpenEdit}
              openEdit={openEdit}
              editedpost={editedPost}
              setPosts={setPosts}
              posts={posts}
            />
          )}
          <PopUp setOpen={setOpen} open={open} setConfirm={Confirm} />
        </div>
        <div className="flex h-full my-3 justify-between gap-4">
          <button
            disabled={!(deletList.length > 0)}
            onClick={() => deletePost(deletList, deletList)}
            className="bg-red-500 hover:bg-red-700 text-white  py-2 px-4 border border-red-700 rounded disabled:opacity-75"
          >
            Delete Many <small>N° {deletList.length}</small>
          </button>
          <div className="flex justify-between gap-6">
            <h2 className="py-1 text-gray-500">
              Page Number : <span className="font-bold">{skip + 1}</span>{" "}
            </h2>
            <h2 className="py-1 text-gray-500">
              Today seen ads N°: <span className="font-bold">{todayCount}</span>{" "}
            </h2>
            <h2 className="py-1 text-gray-500">
              Today created ads N°: <span className="font-bold">{todayCreated}</span>{" "}
            </h2>
          </div>
          <div className="flex gap-4">
            <button
              className="rounded-md bg-sky-100 px-2 py-1 text-sky-400 border-sky-400 border-2 "
              onClick={hundalePrev}
              disabled={skip === 0}
            >
              Prev
            </button>
            <button
              className="rounded-md bg-sky-100 px-2 py-1 text-sky-400 border-sky-400 border-2"
              onClick={hundaleNext}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </main>
  );
}
