import React from 'react'

export default function Terms() {
    return (
        <main >
            {/* <!-- Heading --> */}
            <div className="md:max-w-screen-sm text-center px-4 sm:px-6 lg:px-6 pt-16 pb-6 mx-auto">
                <h1 className="text-2xl font-bold md:text-4xl ">Terms of service</h1>
            </div>
            {/* <!-- End Heading --> */}

            {/* <!-- Content --> */}
            <div className="md:max-w-screen-sm lg:max-w-6xl px-4 sm:px-6 lg:px-8 pb-12 md:pt-6 sm:pb-20 mx-auto">
                <div className="grid gap-4 md:gap-8">
                    <div>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">1. Terms</h2>
                        <p className="mb-5 dark:text-gray-600">
													By accessing this Website, accessible from adsteak.com, you are agreeing to be bound by these Website Terms of Service and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.
												</p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">2. Service License</h2>
                        <p className="mb-5 dark:text-gray-600">
													Permission is granted to temporarily download one copy of the materials on adsteak's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
													modify or copy the materials;
													use the materials for any commercial purpose or for any public display;
													attempt to reverse engineer any software contained on adsteak's Website;
													remove any copyright or other proprietary notations from the materials; or
													transferring the materials to another person or "mirror" the materials on any other server.
													This will let adsteak to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.
												</p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">3. Disclaimer</h2>
												<p className="mb-5 dark:text-gray-600">
													All the materials on adsteak’s Website are provided "as is". adsteak makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, adsteak does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.
												</p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">4. Revisions and Errata</h2>
                        <p className="mb-5 dark:text-gray-600">
													The materials appearing on adsteak’s Website may include technical, typographical, or photographic errors. adsteak will not promise that any of the materials in this Website are accurate, complete, or current. adsteak may change the materials contained on its Website at any time without notice. adsteak does not make any commitment to update the materials.
												</p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">5. Site Terms of Use Modifications</h2>
                        <p className="mb-5 dark:text-gray-600">
												adsteak may revise these Terms of Service for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms of Service.
												</p>
												<h2 className="text-lg sm:text-xl font-semibold mb-2 ">6. Your Privacy</h2>
                        <p className="mb-5 dark:text-gray-600">
													Please read our <a target='_blank' href="https://adsteak.com/privacy_policy">Privacy Policy.</a>
												</p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">7. Governing Law</h2>
                        <p className="mb-5 dark:text-gray-600">
													Any claim related to Lightfunnels's Website shall be governed by the laws of gb without regards to its conflict of law provisions.
												</p>
                    </div>

                    {/* <div>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">Privacy Changes</h2>

                        <p className="dark:text-gray-600">If we change our privacy policy we will post those changes on this page. Registered users will be sent an email that outlines changes made to the privacy policy.</p>
                    </div> */}
                </div>
            </div>
            {/* <!-- End Content --> */}
        </main>
    )
}
