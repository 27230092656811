import React, { useEffect, useState, Fragment, useRef } from "react";
import API from "../API";
import Filter from "./Filter";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ArrowSmUpIcon,
  SearchIcon,
  SortDescendingIcon,
} from "@heroicons/react/outline";
import PostItem from "./postItem";
import Loading from "./loading";
import { FilterSelect } from "./filter-select";
import PaginationNav1 from "./PaginatedItems";
import PermitionModal from "../pages/payment/PermissionModal";
import number from "numeral";

export default function Home(props) {
  const { userInfo, data, setData, init, setIsUpdatedUser } = props;
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disable, setDsibale] = useState(false);
  const [noResult, setnoResult] = useState(false);
  const [skip, setSkip] = useState(0);
  const [reset, setReset] = useState({});
  const [stop, setStop] = useState(true);

  const [refreshKey, setKey] = useState(null);

  const { user, getAccessTokenSilently } = useAuth0();
  const [pageIndex, setPageIndex] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(null);
  const pageItems = 60;

  const [postsKey, setPostsKey] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const abortControllerRef = useRef(null);

  const initialValue = {
    country: undefined,
    t_lang: undefined,
    button: undefined,
    platform: undefined,
    ad_format: undefined,
    ads_type: undefined,
    Category: undefined,
    reaction: { name: "like", min: "" },
    sort: "last_seen",
    filter_name: undefined,
    search: undefined,
    calander: undefined,
    seen_between: undefined,
    fetured: false,
  };
  const [value, setValue] = useState(initialValue);

  //maybe need to clean
  const [myDraftList, setMyDraftList] = useState([]);
  const [myList, setMyList] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [playing, setPlaying] = useState(-1);

  const [filtered, setFiltered] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function clearAll() {
    if (skip > 0) {
      setSkip(0);
    }

    const nd = {
      ...init,
      social_type: data.social_type,
      ads_type: data.ads_type,
    };
    setData(nd);
    setValue({
      ...initialValue,
      social_type: data.social_type,
      ads_type: data.ads_type,
    });

    setKey(Math.random() * 100 + "apply");
  }
  const currentRequestId = useRef(0);

async function GetPosts() {
  // Increment the request ID
  const requestId = ++currentRequestId.current;

  // Show the loader for the new request
  setLoading(true);

  // Abort the previous request
  if (abortControllerRef.current) {
    abortControllerRef.current.abort();
  }

  // Create a new AbortController
  const abortController = new AbortController();
  abortControllerRef.current = abortController;

  try {
    let postData = posts;
    if (userInfo) {
      if (skip == 0) {
        postData = [];
        setPosts([]);
      }
      setStop(false);

      const res = await API.get(
        `posts?user_id=${userInfo?._id}&data=${JSON.stringify(
          data
        )}&skip=${skip}&filtered=${filtered}`,
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
          signal: abortController.signal,
        }
      );

      // Only update state if this is the latest request
      if (res?.status === 200 && requestId === currentRequestId.current) {
        let newdata = [...postData, ...res?.data?.posts];
        setPosts(newdata);
        setPageIndex(newdata.length / 20);
        setTotalDocuments(res?.data?.totalPosts);
        setStop(true);
      }
    }
  } catch (error) {
    if (error.name === "AbortError") {
      console.log("Request aborted");
    } else if (error?.response?.status === 429) {
      if (error?.response?.data.message === "free account" && skip == 0) {
        clearAll();
      }
      setViewModal(true);
      setnoResult(true);
    }
  } finally {
    // Ensure we only hide the loader for the latest request
    if (requestId === currentRequestId.current) {
      setLoading(false);
    }
  }
}

  useEffect(() => {
    if (
      userInfo?._id &&
      ((typeof refreshKey === "string" && !refreshKey.includes("value")) ||
        refreshKey === null)
    ) {
      GetPosts();
    }
  }, [skip, refreshKey, userInfo?._id, data.fetured, filtered]);

  // useEffect(() => {
  //   if (data?.filter_name && data.sort === "last_seen") {
  //     setReset({ sort: "last_seen" });
  //   }
  // }, [data?.filter_name]);
  // useEffect(() => {
  //   if (posts.length == 20*skip) {
  //     setLoading(false)
  //   }
  //   // setLoading(true)

  // }, [posts]);

  const handleScroll = (event) => {
    const el = event.target.documentElement;

    if (
      el.scrollHeight - el.scrollTop < el.scrollHeight / 2 &&
      userInfo?._id &&
      !loading
    ) {
      setSkip(pageIndex);
    }
  };

  window.addEventListener("scroll", handleScroll);

  // useEffect(() => {
  //   async function favorites(){
  //     try {
  //       API.get(`favorites?email=${user.email}`, {
  //         headers: {
  //           Authorization: `Bearer ${await getAccessTokenSilently()}`,
  //         },
  //       }).then((res) => {
  //         setMyList([]);
  //         res.data.forEach((element) => {
  //           setMyList((prev) => [...prev, element.post?._id]);
  //         });
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   favorites();
  // }, []);

  // useEffect(() => {
  //   async function draft() {
  //     try {
  //       API.get(`draft?email=${user.email}`, {
  //         headers: {
  //           Authorization: `Bearer ${await getAccessTokenSilently()}`,
  //         },
  //       }).then((res) => {
  //         setMyDraftList([]);
  //         res.data.forEach((element) => {
  //           setMyDraftList((prev) => [...prev, { _id: element?.post?._id, isDeleted: element?.isDeleted, isExported: element?.isExported }]);
  //         });
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   draft();
  // }, []);

  return (
    <Fragment>
      <main
        className="relative h-[calc(100%_-_112px)]"
        // onMouseEnter={() => setPlaying(-1)}
      >
        <Filter
          init={init}
          data={data}
          setData={setData}
          disable={disable}
          // handleSearch={handleSearch}
          setKey={setKey}
          refreshKey={refreshKey}
          skip={skip}
          setSkip={setSkip}
          reset={reset}
          value={value}
          setValue={setValue}
          initialValue={initialValue}
          totalDocuments={totalDocuments}
          userInfo={userInfo}
        />
        <div className="h-full flex flex-col max-w-8xl mx-auto px-4 sm:px-6 lg:px-10 mt-5">
          {/* {(userInfo?.email === "mathamjad@gmail.com" ||
            userInfo?.email === "dahriiismail@gmail.com") && (
            <div className="flex mb-4 gap-3">
              <div className="flex gap-2.5">
                <div>Filter by interested</div>
                <label className="toggle-switch">
                  <input
                    onChange={() => {
                      setFiltered(!filtered);
                    }}
                    type="checkbox"
                    className="switch"
                    id="switch"
                  />
                  <span className="slider" />
                </label>
              </div>
              <div>
                Filtered Posts: {posts.length > 0 ? posts.length : "NA"}
              </div>
            </div>
          )} */}
          {!loading && noResult && posts.length === 0 && (
            <div className="mt-3">
              <div className="text-center h-full flex flex-col mt-3  justify-center items-center min-h-[250px]">
                <div className="mb-3 p-3 bg-slate-200 rounded-full">
                  <SearchIcon className="w-6 text-gray-400" />
                </div>
                <h3 className="mt-2 text-sm font-medium text-gray-900 ">
                  No result found
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Try different keyword
                </p>
              </div>
            </div>
          )}

          {posts.length > 0 ? (
            <Fragment>
              {/* <div className="flex items-center justify-between mb-5">
								{totalDocuments && !data.fetured && (
									<h2 className="whitespace-nowrap capitalize text-2xl font-medium text-zinc-900 mr-3">
										{data.social_type.replace('_', ' ')} Posts: {number(totalDocuments).format('0,00')}
									</h2>
								)}
								<div className="flex items-center">
									<div className="mr-2 flex items-center w-full text-gray-600">
										<SortDescendingIcon className="w-5 mr-2" />
										<span className="lg:block hidden w-[60px]">Sort by:</span>
									</div>
									<FilterSelect
										name="sort"
										value={value['sort']}
										disabled={!userInfo?.stripe_subscription}
										options={
											<>
												<option className="text-gray-300" hidden>
													Sort
												</option>
												<option value="post_create">Post creation date</option>
												<option value="last_seen">Last seen</option>
												{data.social_type !== 'facebook_library' && <>
													<option value="video_views">Impression</option>
													<option value="like">Likes</option>
													<option value="comment">Comments</option>
													<option value="share">Shares</option>
												</>}
												{data.social_type === 'facebook_library' && <>
													<option value="ads_count">Ads Count</option>
												</>}
											</>
										}
										handleFilter={(e) => {
                      e.preventDefault()
                      setData(prev => ({...prev, sort: e.target.value}))
                      setValue(prev => ({...prev, sort: e.target.value}))
                    }}
										htmlFor="sort"
									/>
								</div>
							</div> */}
              <ul
                id="header"
                className="grid grid-cols-[repeat(auto-fit,minmax(285px,1fr))] gap-5"
                // onMouseEnter={() => setPlaying(-1)}
                key={postsKey}
              >
                {posts.map((post, index, posts) => (
                  <li
                    key={post._id}
                    className="col-span-1 bg-white rounded-lg border-[1px] border-[#E2E8F0] w-full "
                  >
                    <PostItem
                      post={post}
                      user={user}
                      posts={posts}
                      myList={myList}
                      setMyList={setMyList}
                      myDraftList={myDraftList}
                      setMyDraftList={setMyDraftList}
                      // onMouseEnter={() => setPlaying(index)}
                      // onMouseLeave={() => setPlaying(-1)}
                      playing={playing}
                      setPlaying={setPlaying}
                      index={index}
                      setPosts={setPosts}
                      setPostsKey={setPostsKey}
                      setDeleteMessage={setDeleteMessage}
                      deleteMessage={deleteMessage}
                      userInfo={userInfo}
                      data={data}
                      skip={skip}
                    />
                  </li>
                ))}
              </ul>
            </Fragment>
          ) : null}
          {/* {posts.length > 0 && userInfo.stripe_subscription && ( */}
          <Fragment>
            <button
              type="button"
              className="fixed right-[30px] bottom-[90px] inline-flex items-center p-3 border border-gray-300 shadow-sm text-base font-medium rounded-full text-gray-700 bg-white hover:text-[#ff6c3d] text-[#808080]   hover:border-[#ff6c3d] focus:outline-none "
              onClick={scrollToTop}
            >
              <ArrowSmUpIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            {loading && <Loading />}
            {/* { userInfo && userInfo.roles.includes('admin') &&   <div className="flex items-center justify-center  overflow-y-auto">
              <div className="flex flex-col items-center">
                <div className="flex gap-3 flex-wrap p-6 py-8 overflow-y-auto ">
                  <PaginationNav1
                  userInfo={userInfo}
                    gotoPage={setPageIndex}
                    canPreviousPage={pageIndex > 0}
                    totalDocuments={totalDocuments}
                    pageItems={pageItems}
                    pageIndex={pageIndex}
                    setSkip={setSkip}
                    setData={setData}
                    scrollToTop={scrollToTop}
                  />
                </div>
              </div>
            </div>
            } */}
          </Fragment>
          {/* )} */}
          <PermitionModal
            message="You have reached the maximum number of viewed ads in your plan."
            viewModal={viewModal}
            setViewModal={setViewModal}
          />
        </div>
      </main>
    </Fragment>
  );
}
