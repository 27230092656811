import React from 'react'

export default function ContactUs() {
    return (
        <main >
            {/* <!-- Heading --> */}
            <div className="md:max-w-screen-sm text-center px-4  pt-16 pb-6 mx-auto">
                <h1 className="text-2xl font-bold md:text-4xl ">alladspy Terms &amp; Conditions of Use </h1>
            </div>
            {/* <!-- End Heading --> */}

            {/* <!-- Content --> */}
            <div className="md:max-w-screen-sm lg:max-w-6xl px-4 sm:px-6 lg:px-8 pb-12 md:pt-6 sm:pb-20 mx-auto">
                <div className="grid gap-4 md:gap-8">
                    <div>
                        <p className="mb-5 dark:text-gray-600">Please read these terms and conditions of use carefully before accessing, using or obtaining any materials, information, products or services. By accessing, the our website, extension, mobile or tablet application, or any other feature or other platform (collectively “Our Website”) you agree to be bound by these terms and conditions (“Terms”) and our Privacy Policy. </p>
                        <p className="mb-5 dark:text-gray-600">If you do not accept all of these Terms, then you may not use our website. In these terms, "we", "us", "our" refers to alladspy , and "you" or "your" refers to you as the user of our website.</p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">1. Terms</h2>
                        <p className="mb-5 dark:text-gray-600">By accessing this Website, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trademark law.
                        </p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">2.REFUND POLICY</h2>
                        <p className="mb-5 dark:text-gray-600">As a SaaS provider, all products and services offered by alladspy and its business units are non-tangible and irrevocable, so we do not issue refunds once the order is completed and delivered. We hope you can read the terms and conditions carefully before making any payment. </p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">Acceptance of Refund Policy</h2>
                        <p className="mb-5 dark:text-gray-600">It is highly recommended to know the functionality and features of our product before placing an order to avoid the circumstances of refunds.
                            By ordering our products, you agree that you have properly read this policy and fully accept the terms & conditions in this refund policy.
                            If you don't agree or accept the terms & conditions in this policy, please contact us to relay your comments, suggestions, or corrections, we would appreciate it.
                            For any request or general query, please give us a maximum of 48 hours to get back to you.
                            In rare cases, we ACCEPT refund requests for the following reasons ONLY：
                        </p>
                        <p className="mb-5 dark:text-gray-600">
                            Errors occur due to our system bug.
                            Users are entitled to receive a full refund within 24 HOURS of the first payment.
                            Note:</p>
                        <p className="mb-5 dark:text-gray-600">1) The first payment means initial payment with an identified account, It doesn’t include renewal, plan upgrade, or repurchase.
                        </p>
                        <p className="mb-5 dark:text-gray-600">2) Please provide us the thorough and supported reasons for the refund.</p>
                        <p className="mb-5 dark:text-gray-600">3) We do not guarantee refunds for lack of usage or dissatisfaction.</p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">Subscription Terms
                        </h2>
                        <p className="mb-5 dark:text-gray-600">Your subscription begins as soon as your initial payment is processed. Your subscription will automatically renew at the end of each period without notice until you cancel. You authorize us to store your payment method(s) and to automatically charge your payment method(s) every period until you cancel.</p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">Cancellation Terms
                        </h2>
                        <p className="mb-5 dark:text-gray-600"> You may cancel your subscription any time, via Profile→My Plan→"cancel the subscription" or by contacting the online customer service, before your renewal and avoid billing of the next period’s fee to your chosen payment method. Your cancellation will be effective as of the last day of the period for which you paid a fee. You may continue to use the subscription until your cancellation is effective and no refund will be issued.</p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">3. Use License</h2>
                        <p className="mb-5 dark:text-gray-600">Permission is granted to temporarily download one copy of the materials on our Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:

                        </p>
                        <p className="mb-5 dark:text-gray-600">modify or copy the materials;</p>
                        <p className="mb-5 dark:text-gray-600"> use the materials for any commercial purpose or for any public display;
                        </p>
                        <p className="mb-5 dark:text-gray-600"> attempt to reverse engineer any software contained on our Website;
                        </p>
                        <p className="mb-5 dark:text-gray-600"> remove any copyright or other proprietary notations from the materials; or
                        </p>
                        <p className="mb-5 dark:text-gray-600"> transferring the materials to another person or "mirror" the materials on any other server.
                        </p>
                        <p className="mb-5 dark:text-gray-600"> This will let us to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.
                        </p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">4. Disclaimer</h2>
                        <p className="mb-5 dark:text-gray-600">All the materials on our Website are provided "as is". We make no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, we do not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.
                        </p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">5. Limitations</h2>
                        <p className="mb-5 dark:text-gray-600">We or its suppliers will not be held accountable for any damages that will arise with the use or inability to use the materials on our Website, even if we or an authorized representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">6. Revisions and Errata</h2>
                        <p className="mb-5 dark:text-gray-600">The materials appearing on our Website may include technical, typographical, or photographic errors.  We will not promise that any of the materials on this website are accurate, complete, or current.  We may change the materials contained on its Website at any time without notice. We do not make any commitment to update the materials.</p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">7. Links</h2>
                        <p className="mb-5 dark:text-gray-600">Our service has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by the site. The use of any linked website is at the user’s own risk.
                        </p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">8. Site Terms of Use Modifications</h2>
                        <p className="mb-5 dark:text-gray-600">Our service may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.
                        </p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">9. Governing Law</h2>
                        <p className="mb-5 dark:text-gray-600">Any claim related to our Website shall be governed by the laws of us without regard to its conflict of law provisions.
                        </p>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">10. Communication
                        </h2>
                        <p className="mb-5 dark:text-gray-600">alladspy will contact you by email, text message, or by posting notices or other services on the Service/Website. You agree that all agreements, notices, disclosures and other information provided to you by alladspy in these ways are in compliance with relevant laws and regulations. You will be deemed to have received all agreements, representations, disclosures and other information if alladspy sends you information in such form or gives notice thereof on the Service/Website. alladspy reserves the right to interpret and amend this Agreement to the fullest extent permitted by law. If you have any comments about alladspy and the Service, please feel free to contact us by email. For inquiries, please email: Support@alladspy.com.
                        </p>
                    </div>

                    {/* <div>
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 ">Privacy Changes</h2>

                        <p className="dark:text-gray-600">If we change our privacy policy we will post those changes on this page. Registered users will be sent an email that outlines changes made to the privacy policy.</p>
                    </div> */}
                </div>
            </div>
            {/* <!-- End Content --> */}
        </main>
    )
}
