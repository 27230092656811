import React from 'react'
import { useState } from 'react';
import PaymentModal from './PaymentModal';
import { useAuth0 } from '@auth0/auth0-react';
// import { Radio, RadioGroup } from '@headlessui/react'
// import { CheckIcon } from '@heroicons/react/20/solid'
function Plans() {
    return (
        <div>
            <section className="bg-white ">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">Designed for business teams like yours</h2>
                        <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.</p>
                    </div>
                    <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                        {/* Pricing Card */}
                        <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg order border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                            <h3 className="mb-4 text-2xl font-semibold">Starter</h3>
                            <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">Best option for personal use &amp; for your next project.</p>
                            <div className="flex justify-center items-baseline my-8">
                                <span className="mr-2 text-5xl font-extrabold">$29</span>
                                <span className="text-gray-500 dark:text-gray-400">/month</span>
                            </div>
                            {/* List */}
                            <ul role="list" className="mb-8 space-y-4 text-left">
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Individual configuration</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>No setup, or hidden fees</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Team size: <span className="font-semibold">1 developer</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Premium support: <span className="font-semibold">6 months</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Free updates: <span className="font-semibold">6 months</span></span>
                                </li>
                            </ul>
                            <a href="#" className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg ext-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Get started</a>
                        </div>
                        {/* Pricing Card */}
                        <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg order border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                            <h3 className="mb-4 text-2xl font-semibold">Company</h3>
                            <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">Relevant for multiple users, extended &amp; premium support.</p>
                            <div className="flex justify-center items-baseline my-8">
                                <span className="mr-2 text-5xl font-extrabold">$99</span>
                                <span className="text-gray-500 dark:text-gray-400 " >/month</span>
                            </div>
                            {/* List */}
                            <ul role="list" className="mb-8 space-y-4 text-left">
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Individual configuration</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>No setup, or hidden fees</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Team size: <span className="font-semibold">10 developers</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Premium support: <span className="font-semibold">24 months</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Free updates: <span className="font-semibold">24 months</span></span>
                                </li>
                            </ul>
                            <a href="#" className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg ext-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Get started</a>
                        </div>
                        {/* Pricing Card */}
                        <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg order border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                            <h3 className="mb-4 text-2xl font-semibold">Enterprise</h3>
                            <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">Best for large scale uses and extended redistribution rights.</p>
                            <div className="flex justify-center items-baseline my-8">
                                <span className="mr-2 text-5xl font-extrabold">$499</span>
                                <span className="text-gray-500 dark:text-gray-400">/month</span>
                            </div>
                            {/* List */}
                            <ul role="list" className="mb-8 space-y-4 text-left">
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Individual configuration</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>No setup, or hidden fees</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Team size: <span className="font-semibold">100+ developers</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Premium support: <span className="font-semibold">36 months</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Free updates: <span className="font-semibold">36 months</span></span>
                                </li>
                            </ul>
                            <a href="#" className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg ext-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Get started</a>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

function Plans_2({ userInfo }) {
    const [viewModal, setViewModal] = useState(false);
    const [plan, setPlan] = useState('');
    const { user } = useAuth0();
    const paymentLink = process.env.REACT_APP_STRIPE_PAYMENT_LINK;

    // const [subscription, sessionUrl, loading] = useStripeInit();
    // live link - https://buy.stripe.com/dR6dSAgYo7HB3WU289
    const basicPlan = userInfo && `${paymentLink}?client_reference_id=${userInfo._id}&prefilled_email=${userInfo.email}`
    // const proPlan = subscription ? sessionUrl : `https://buy.stripe.com/test_9AQfZRakcgcTfWo5ko?prefilled_email=${user.email}`
    // const vipPlan = subscription ? sessionUrl : `https://buy.stripe.com/test_8wM00T9g82m3cKc005?prefilled_email=${user.email}`

    return (
        <div>
            <div>
                {/* component */}
                {/* <link rel="preconnect" href="https://fonts.gstatic.com" /> */}
                {/* {/* <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" /> */}



                <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
                <div className="min-h-screen flex justify-center items-center">
                    <div className="mx-auto mt-16 max-w-2xl rounded-lg border border-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none shadow-xl">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900">One simple plan</h3>
                            <p className="mt-6 text-base leading-7 text-gray-600">Get started today and transform your e-commerce business with battle tested winning products.</p>
                            <div className="mt-10 flex items-center gap-x-4">
                                <h4 className="flex-none text-sm font-semibold leading-6 text-[#ff6c3d]">What’s included</h4>
                                <div className="h-px flex-auto bg-gray-100"></div>
                            </div>
                            <ul role="list" className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                                <li className="flex gap-x-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="h-6 w-5 flex-none text-[#ff6c3d]">
                                        <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd"></path>
                                    </svg> All-inclusive pricing, every feature included.
                                </li>
                                <li className="flex gap-x-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="h-6 w-5 flex-none text-[#ff6c3d]">
                                        <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd"></path>
                                    </svg> Facebook, Instagram, TikTok and Pinterest
                                </li>
                                <li className="flex gap-x-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="h-6 w-5 flex-none text-[#ff6c3d]">
                                        <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd"></path>
                                    </svg> Thousands of ads added daily
                                </li>
                                <li className="flex gap-x-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="h-6 w-5 flex-none text-[#ff6c3d]">
                                        <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd"></path>
                                    </svg> A selection of hand picked winning products
                                </li>
                            </ul>
                        </div>
                        <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                            <div className="rounded-lg h-full bg-[#FAE3D9] py-10 text-center lg:flex lg:flex-col lg:justify-center lg:py-16">
                                <div className="mx-auto max-w-xs px-2">
                                    <span className="leading-6 text-4xl tracking-wide text-gray-500 font-bold">
                                        <span className="line-through">$34.95</span>
                                        <span className="text-xl">/mo</span>
                                    </span>
                                    <span className="leading-6 tracking-wide text-gray-600 block my-4">Try it for 2 days for</span>
                                    <span className="text-4xl font-bold tracking-tight text-gray-900 block">Just $1</span>
                                    <p className="text-base mt-6 text-gray-600">
                                        <span className="leading-6 tracking-wide text-gray-600">Then $34.95/mo - Cancel anytime</span>
                                    </p>

                                    {userInfo?.stripe_subscription?.plan?.nickname === 'basic' ? (
                                        <p className="w-full py-4 bg-white mt-8 rounded-xl text-orange-500">
                                            <span className="font-medium">
                                                Current Plan
                                            </span>
                                            {/* <span className="pl-2 material-icons align-middle text-sm">
                                                    east
                                            </span> */}
                                        </p>
                                    ) : (
                                        <a
                                            className='w-full'
                                            href={basicPlan}
                                        >
                                            <p className="w-full text-2xl fontem py-4 bg-orange-500 mt-8 rounded-xl flex items-center justify-center text-white">
                                                <span className="font-medium">
                                                    Get started now
                                                </span>
                                            </p>
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className>
                        {/* <div className="text-center font-semibold">
                            <h1 className="text-4xl font-bold">
                                <span className="tracking-wide">Pick your plan.</span>
                            </h1>
                        </div> */}
                        <div className="pt-24 flex flex-row">
                            {/* Basic Card */}
                            {/* <div className="p-8 bg-white text-center rounded-3xl shadow-xl">
                                <h1 className="text-black font-semibold text-2xl">Starter</h1>
                                <p className="pt-2 tracking-wide">
                                    <span className="text-gray-400 align-top">$ </span>
                                    <span className="text-3xl font-semibold">39.99</span>
                                    <span className="text-gray-400 font-medium">/ month</span>
                                </p>
                                <hr className="mt-4 border-1" />
                                <div className="pt-8">
                                    <p className="font-semibold text-gray-400 text-left">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2">
                                            <span className="text-black">
                                                More than 300K posts in database.
                                            </span>
                                        </span>
                                    </p>
                                    <p className="font-semibold text-gray-400 text-left pt-5">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2">
                                            <span className="text-black">50 ads analytics</span> per day
                                        </span>
                                    </p>
                                    <p className="font-semibold text-gray-400 text-left pt-5">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2">
                                            <span className="text-black">50 product analytics</span> per day
                                        </span>
                                    </p>
                                    {userInfo?.stripe_subscription?.plan?.nickname === 'basic' ? (
                                        <p className="w-full py-4 bg-white mt-8 rounded-xl text-orange-500">
                                            <span className="font-medium">
                                                Current Plan
                                            </span>
                                        </p>
                                    ) : (
                                        <a
                                            className='w-full'
                                            href={basicPlan}
                                        >
                                            <p className="w-full py-4 bg-orange-500 mt-8 rounded-xl flex items-center justify-center text-white">
                                                <span className="font-medium">
                                                    Get 2 days $1 offer
                                                </span>
                                                <span className="pl-2 material-icons align-middle text-sm">
                                                    east
                                                </span>
                                            </p>
                                        </a>
                                    )}
                                </div>
                            </div> */}
                            {/* StartUp Card */}
                            {/* <div className="w-80 p-8 bg-orange-500 text-center rounded-3xl text-white border-4 shadow-xl border-white transform scale-125">
                                <h1 className="text-white font-semibold text-2xl">Pro</h1>
                                <p className="pt-2 tracking-wide">
                                    <span className="text-white align-top">$ </span>
                                    <span className="text-3xl font-semibold">99.99</span>
                                    <span className="text-white font-medium">/ month</span>
                                </p>
                                <hr className="mt-4 border-1 border-white" />
                                <div className="pt-8">
                                    <p className="font-semibold text-white text-left">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2">
                                            <span className="text-white">1000 ads </span> per query
                                        </span>
                                    </p>
                                    <p className="font-semibold text-white text-left pt-5">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2">
                                            <span className="text-white">200 ad analytics</span> per day
                                        </span>
                                    </p>
                                    <p className="font-semibold text-white text-left pt-5">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2 text-gray-300">
                                            <span className="text-white">200 product analytics</span> per day
                                        </span>
                                    </p>
                                    {subscription?.plan?.nickname === 'pro' ? (
                                        <p className="w-full py-4 bg-white mt-8 rounded-xl text-orange-500">
                                            <span className="font-medium">
                                                    Current Plan
                                            </span>
                                            <span className="pl-2 material-icons align-middle text-sm">
                                                    east
                                            </span>
                                        </p>
                                    ) : (
                                        <a
                                            href={!loading ? proPlan : ''}
                                        >
                                            <p className="w-full py-4 bg-white mt-8 rounded-xl flex items-center justify-center text-orange-500">
                                                {loading ? (
                                                    <LoadingSpinner/>
                                                ) : (
                                                    <Fragment>
                                                        <span className="font-medium">
                                                                Choose Plan
                                                        </span>
                                                        <span className="pl-2 material-icons align-middle text-sm">
                                                                east
                                                        </span>
                                                    </Fragment>
                                                )}
                                            </p>
                                        </a>
                                    )}
                                </div>
                                <div className="absolute top-4 right-4">
                                    <p className="bg-orange-500 font-semibold px-4 py-1 rounded-full uppercase text-xs">Popular</p>
                                </div>
                            </div> */}
                            {/* Enterprise Card */}
                            {/* <div className="w-96 p-8 bg-white text-center rounded-3xl pl-16 shadow-xl">
                                <h1 className="text-black font-semibold text-2xl">VIP</h1>
                                <p className="pt-2 tracking-wide">
                                    <span className="text-gray-400 align-top">$ </span>
                                    <span className="text-3xl font-semibold">149.99</span>
                                    <span className="text-gray-400 font-medium">/ month</span>
                                </p>
                                <hr className="mt-4 border-1" />
                                <div className="pt-8">
                                    <p className="font-semibold text-gray-400 text-left">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2">
                                            <span className="text-black">2000 ads</span> per query
                                        </span>
                                    </p>
                                    <p className="font-semibold text-gray-400 text-left pt-5">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2">
                                            <span className="text-black">600 ad analytics</span> per day
                                        </span>
                                    </p>
                                    <p className="font-semibold text-gray-400 text-left pt-5">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2">
                                            <span className="text-black">600 product analytics</span> per day
                                        </span>
                                    </p>
                                    {subscription?.plan?.nickname === 'vip' ? (
                                        <p className="w-full py-4 bg-orange-500 cursor-not-allowed mt-8 rounded-xl text-white">
                                            <span className="font-medium">
                                                    Current Plan
                                            </span>
                                        </p>
                                    ) : (
                                        <a
                                            href={!loading ? vipPlan : ''}
                                        >
                                            <p className="w-full py-4 bg-orange-500 mt-8 flex items-center justify-center rounded-xl text-white">
                                                {loading ? (
                                                    <LoadingSpinner />
                                                ) : (
                                                    <Fragment>
                                                        <span className="font-medium">
                                                                Choose Plan
                                                        </span>
                                                        <span className="pl-2 material-icons align-middle text-sm">
                                                                east
                                                        </span>
                                                    </Fragment>
                                                )}
                                            </p>
                                        </a>
                                    )}
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <PaymentModal viewModal={viewModal} setViewModal={setViewModal} plan={plan} />
        </div >
    )
}


export default function PlansPage({ userInfo }) {
    return (
        <>
            {/* <Header logo={logohorizontal} /> */}
            <main className="mt-[-50px] bg-slate-100">
                <section
                    className="overflow-hidden "
                    style={{
                        background:
                            "linear-gradient(180deg, #FFFFFF 11.91%, #F1F5F9 40.16%, #F1F5F9 100%)",
                    }}
                >
                    {/* <Plans /> */}
                    <Plans_3 userInfo={userInfo} />
                </section>

            </main>
        </>
    )
}








function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Plans_3({ userInfo }) {
    let params = userInfo && `?client_reference_id=${userInfo._id}&prefilled_email=${userInfo.email}`

    const tiers = [
        {
            name: 'Starter',
            id: 'tier-starter',
            href: `${process.env.REACT_APP_STRIPE_PAYMENT_LINK_WITHOUT_TRIAL}${params}`,
            trial: `${process.env.REACT_APP_STRIPE_PAYMENT_LINK}${params}`,
            price: { monthly: '19.9' },


            icon: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-award w-[36px]" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ff6c3d" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
            </svg>,
            description: 'The best choice to start TikTok ads for your business.',
            features: [
                '200 ads per query',
                '100 ad analytics per day',
                '100 product analytics per day',
                'Facebook Ads',
                'Facebook Librray ads',
                'Tiktok Ads',
                'Instagram Ads',
                'Tiktok Trending Products',
                'Pinterest Ads',
                'Dropshipping Winning products'
            ],
            mostPopular: false,
            start: true,
        },
        {
            name: 'VIP',
            id: 'tier-vip',
            href: `${process.env.REACT_APP_STRIPE_PAYMENT_LINK_WITHOUT_TRIAL_VIP}${params}`,
            trial: `${process.env.REACT_APP_STRIPE_PAYMENT_LINK_VIP}${params}`,
            price: { monthly: '49.9' },
            description: "The essential tool for professionals, it's a no brainer.",

            icon: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-award w-[36px]" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ff6c3d" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 9m-6 0a6 6 0 1 0 12 0a6 6 0 1 0 -12 0" />
                <path d="M12 15l3.4 5.89l1.598 -3.233l3.598 .232l-3.4 -5.889" />
                <path d="M6.802 12l-3.4 5.89l3.598 -.233l1.598 3.232l3.4 -5.889" />
            </svg>,
            features: [
                '1000 ads per query',
                '600 ad analytics per day',
                '600 product analytics per day',
                'Facebook Ads',
                'Facebook Librray ads',
                'Tiktok Ads',
                'Instagram Ads',
                'Tiktok Trending Products',
                'Pinterest Ads',
                'Dropshipping Winning products'
            ],
            mostPopular: true,
            start: true,

        },
        {
            name: 'PRO',
            id: 'tier-pro',
            href: `${process.env.REACT_APP_STRIPE_PAYMENT_LINK_WITHOUT_TRIAL_PRO}${params}`,
            trial: `${process.env.REACT_APP_STRIPE_PAYMENT_LINK_PRO}${params}`,
            price: { monthly: '99.9' },
            icon: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-award w-[36px]" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ff6c3d" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M16 16l2 -11l-4 4l-2 -5l-2 5l-4 -4l2 11" />
                <path d="M8 16l-1.447 .724a1 1 0 0 0 -.553 .894v2.382h12v-2.382a1 1 0 0 0 -.553 -.894l-1.447 -.724h-8z" />
                <path d="M12 4m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                <path d="M6 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                <path d="M18 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
            </svg>,
            description: 'Multi-user, scale-up and sales expansion.',
            features: [
                '2000 ads per query',
                '1200 ad analytics per day',
                '1200 product analytics per day',
                'Facebook Ads',
                'Facebook Librray ads',
                'Tiktok Ads',
                'Instagram Ads',
                'Tiktok Trending Products',
                'Pinterest Ads',
                'Dropshipping Winning products'
            ],
            mostPopular: false,
            start: false,
        },
    ]
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        Find your perfect plan
                    </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                    Start your business easily with AdSteak
                </p>

                <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {tiers.map((tier) => (
                        <div
                            key={tier.id}
                            className={classNames(
                                tier.mostPopular ? 'ring-[2px] ring-[#ff6c3d]' : ' border-[1px] border-[#E2E8F0]  ',
                                'rounded-lg font-bold  p-8 xl:p-10 bg-[#fff] shadow-lg',
                            )}
                        >
                            <div className="flex items-center justify-between gap-x-4">
                                <div
                                    id={tier.id}

                                >

                                    <div className=' flex justify-center items-end gap-1'>
                                        {tier.icon}  <span className='text-2xl font-bold text-gray-700'>{tier.name}</span>  </div>
                                </div>
                                {tier.mostPopular ? (
                                    <p className="rounded-lg  bg-[#ff6c3d]/10 px-2.5 py-1 text-xs font-semibold leading-5 text-[#ff6c3d]">
                                        Most popular
                                    </p>
                                ) : null}
                            </div>
                            {/* <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p> */}
                            <p className="my-8  flex justify-center items-end  gap-x-1">
                                <span className="text-2xl font-semibold pb-[1px] text-gray-700">$</span>
                                <span className="text-4xl font-bold tracking-tight text-gray-700">{tier.price.monthly}</span>
                                <span className="text-sm font-semibold pb-[1px]  text-gray-600">/month</span>
                            </p>

                            <ul role="list" className=" space-y-3 text-sm  text-gray-600 xl:mt-10">
                                {tier.features.map((feature) => (
                                    <li key={feature} className="flex gap-x-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="h-6 w-5 flex-none text-[#ff6c3d]">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                        </svg>
                                        {feature}


                                    </li>
                                ))}
                            </ul>
                            <a
                                href={userInfo?.customer_id ? tier.href : tier.trial}
                                aria-describedby={tier.id}
                                className={classNames(
                                    'bg-[#ff6c3d] text-white shadow-sm ',
                                    'mt-6 border-[1px] border-[#ff6c3d]  shadow-sm block rounded-lg x-3 p-3 text-center text-md font-bold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ff6c3d]',
                                )}
                            >
                                {(userInfo?.customer_id) ? "Upgrade" : "Start 2-Day $1 Trial"}
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
