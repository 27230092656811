import React, { useEffect, useState } from "react";
import API from "../API";
import { useAuth0 } from "@auth0/auth0-react";
import PostItem from "./postItem";
import { AdsPlatform } from "./Filter";
import { HeartIcon } from "@heroicons/react/solid";
import Loading from "./loading";
import Facebook from "../assets/icons/facebook.svg";
import Instagram from "../assets/icons/instagram.svg";
import Tiktok from "../assets/icons/tiktok.svg";
import Pinterest from "../assets/icons/pinterest.svg";

export default function Favorites({ userInfo }) {
  const [myList, setMyList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user, getAccessTokenSilently } = useAuth0();
  const [social, setSocial] = useState("facebook");
  const [playing, setPlaying] = useState(-1);
  useEffect(() => {
    (async () => {
      try {
        setMyList([]);
        API.get(`favorites?email=${user.email}`, {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }).then((res) => {
          if (res.data) {
            res.data.forEach((element) => {
              if (element?.post) {
                element.post["FavoriteId"] = element._id;
                setMyList((prev) => [...prev, element.post]);
              }
            });
          }
          setIsLoading(false);
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  const handleSocial = (name) => {
    if (social !== name) {
      setSocial(name);
    }
  };
  const platforms = [
    { name: "Facebook", value: "facebook", src: Facebook },
    { name: "Facebook Library", value: "facebook_library", src: Facebook },
    { name: "Instagram", value: "instagram", src: Instagram },
    { name: "Pinterest", value: "pinterest", src: Pinterest },
    { name: "Tiktok", value: "tiktok", src: Tiktok },
  ];

  const list = myList.filter((e) => e.social_type === social);
  return (
    <main className="mt-10 " onMouseEnter={() => setPlaying(-1)}>
      <div className="h-full w-full pt-2 ">
        <AdsPlatform
          data={platforms}
          type={social}
          handleSocialType={handleSocial}
        />
        <div className="w-full h-full py-10">
          {isLoading ? (
            <Loading />
          ) : list.length > 0 ? (
            <ul
              id="header"
              className="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 max-w-8xl mx-auto px-4 sm:px-6 lg:px-10"
              onMouseEnter={() => setPlaying(-1)}
            >
              {myList
                ?.filter((post) => {
                  if (social === "facebook") {
                    return post?.social_type === social || !post?.social_type;
                  } else {
                    return post?.social_type === social;
                  }
                })
                .map((post, index) => (
                  <li
                    key={post._id}
                    className="col-span-1 bg-white rounded-lg border-[1px] border-[#E2E8F0] w-full"
                  >
                    <PostItem
                      post={post}
                      user={user}
                      myList={myList}
                      setMyList={setMyList}
                      posts={myList}
                      // onMouseEnter={() => setPlaying(index)}
                      // onMouseLeave={() => setPlaying(-1)}
                      playing={playing}
                      index={index}
                      userInfo={userInfo}
                    />
                  </li>
                ))}
            </ul>
          ) : (
            <div className="text-center h-full flex flex-col justify-center items-center min-h-[250px]">
              <div className="mb-3 p-3 bg-slate-200 rounded-full">
                <HeartIcon className="w-6 text-slate-100" />
              </div>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No result found
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Try save post by clicking on the heart
              </p>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
