import { ChartBarIcon } from "@heroicons/react/outline";
import { useRef, useState, Fragment, useEffect } from "react";
import Analytics from "./analytics";
import PostStats from "./post-stats";
import AddToFavoriteBtn from "./add-to-favorite-btn";
import { dateFormat, lastseenDateCount } from "../controllers/actions";
import Description from "./Description";
import { FaPlay } from "react-icons/fa";
import ReactPlayer from "react-player";
import AddToStoreBtn from "./AddToStoreBtn";
import AddToDraftBtn from "./add-to-draft-btn";
import { useLocation } from "react-router-dom";
// import Facebook from "../assets/icons/post/facebook.svg";
// import FacebookFilled from "../assets/icons/post/facebook_filled.svg";
// import Instagram from "../assets/icons/post/instagram.svg";
// import InstagramFilled from "../assets/icons/post/instagram_filled.svg";
// import Tiktok from "../assets/icons/post/tiktok.svg";
// import TiktokFilled from "../assets/icons/post/tiktok_filled.svg";
// import Pinterst from "../assets/icons/post/pinterst.svg";
import PinterstFilled from "../assets/icons/post/pinterst_filled.svg";
import { XIcon } from "@heroicons/react/solid";
import API from "../API";
import { Modal } from "./modal";
import LoadingSpinner from "./loading-spinner";
import { Toast } from "./toast";
import { useAuth0 } from "@auth0/auth0-react";
import Facebook from "../assets/icons/facebook.svg";
import Instagram from "../assets/icons/instagram.svg";
import Tiktok from "../assets/icons/tiktok.svg";
import Pinterest from "../assets/icons/pinterest.svg";
export default function PostItem({
  post,
  user,
  myList,
  setMyList,
  posts,
  store = false,
  onDeleteSuccess,
  myDraftList,
  setMyDraftList,
  // onMouseEnter,
  // onMouseLeave,
  playing,
  setPlaying,
  index,
  setPostsKey,
  setPosts,
  setDeleteMessage,
  deleteMessage,

  userInfo,
  data,
  skip,
}) {
  const [play, setPlay] = useState(false);
  const [muted, setMuted] = useState(false);
  const [controls, setControls] = useState(false);
  const [pip, setPip] = useState(false);
  const [hide, setHide] = useState(true);
  const [viewAnalytics, setViewAnalytics] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [ID, setID] = useState();
  const [socialHover, setSocialHover] = useState(false);
  const location = useLocation();
  const divRef = useRef(null);

  const s3_bucket =
    post.resource.page_logo.length == 0 || post.resource.page_logo.indexOf("/") > -1
      ? `https://aas-bucket.s3.amazonaws.com`
      : "https://aas-bucket.s3.amazonaws.com/uploads";
  const ShowAnalytics = () => {
    setViewAnalytics((prev) => !prev);
    setID(post?._id);
  };
  const SeenextPost = (e) => {
    try {
      e.preventDefault();
      posts.forEach((element, index) => {
        if (element?._id === ID && posts[index + 1]) {
          setID(posts[index + 1]?._id);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  // const handlevedioError = (e) => {
  //   e.target.poster = "";
  // };
  const SeeprevPost = (e) => {
    try {
      e.preventDefault();
      posts.forEach((element, index) => {
        if (element?._id === ID && posts[index - 1]) {
          setID(posts[index - 1]?._id);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [deleting, setDeleting] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (playing !== index) {
      setIsVideoPlaying(false);
    }
  }, [playing, index]);
  const handleClick = () => {
    // onMouseEnter()
    // if(isVideoPlaying){
    //   onMouseLeave()
    // }else {
    //   onMouseEnter()
    // }
    if (!isVideoPlaying) {
      setPlaying(index);
    }
    setIsVideoPlaying(!isVideoPlaying);
  };

  async function removeFromDb(props, postId, indx) {
    const type = props === "page_id" ? "page_id" : undefined;
    if (postId === post._id) {
      try {
        setDeleting(true);
        return await API.get(
          `remove?user_id=${user._id}&post_id=${post._id}&type=${type}`,
          {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          }
        ).then((e) => {
          setDeleteMessage({
            message: e.data.message ?? e.data,
            status: e.status,
            index,
          });
          const newData = posts.filter(function (post) {
            return e.data.data.indexOf(post._id) === -1;
          });
          setPosts(newData);
          setDeleting(false);
          setShowModal(false);
        });
      } catch (error) {
        console.log(error);
        setDeleting(false);
        setShowModal(false);
      }
    }
  }

  const [isStaff, setIsStaff] = useState(false);
  const addtoStaff = async () => {
    try {
      API.patch(
        `posts/${post._id}?role=${JSON.stringify(
          user?.role
        )}&skip=${skip}&data=${JSON.stringify(data)}`,
        { is_fetured: 1 },
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }
      ).then(() => {
        setIsStaff(true);
        alert("Successfully Added To Staff Picks");
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handlePostClick = (link) => {
    if (link) {
      if (link.startsWith("library")) {
        const linkComposition = link.split("/");
        window.open(
          `https://www.facebook.com/ads/library/?id=${linkComposition[1]}`,
          "_blank"
        );
      } else {
        window.open(link, "_blank");
      }
    }
  };
  const Header = () => {
    return (
      <div>
        <div className="w-full flex items-center p-3">
          {post?.ads_count ? (
            <div className="min-w-[40px]">
              <img
                className={`w-10 h-10  object-cover  bg-gray-300 rounded-full border border-gray-300 `}
                // src={`https://aas-bucket.s3.amazonaws.com/uploads/${post?.resource?.page_logo}`}
                src={`${s3_bucket + "/" + post?.resource?.page_logo}`}
                alt=""
              />
              <div className="text-[11px] pt-[6px] line-clamp-1 w-[60px]">Ads: {post?.ads_count}</div>
            </div>
          ) : (
            <img
              className={`w-10 h-10 bg-gray-300 rounded-full border  border-gray-300`}
              // src={`https://aas-bucket.s3.amazonaws.com/uploads/${post?.resource?.page_logo}`}
              // src={post.resource.page_logo.indexOf('/') > -1 ? `https://aas-bucket.s3.amazonaws.com/${post.resource.page_logo}` : `https://aas-bucket.s3.amazonaws.com/uploads/${post.resource.page_logo}`}
              src={`${s3_bucket + "/" + post?.resource?.page_logo}`}
              alt=""
            />
          )}
          <div className="w-full relative flex items-start gap-1 justify-between ml-4">
            <div>
              <h3 className="text-gray-900 font-medium break-all line-clamp-1">
                {post?.page_name}
              </h3>
              <div className=" mt-[-2px] pb-1 text-gray-500">
                <div className="flex flex-row items-center">
                  <p className=" text-gray-500 text-[12px]  mr-2">
                    {/* Last seen: {new Date(post?.last_seen).toLocaleDateString().replaceAll("/","-")} */}
                    Last seen: {dateFormat(post?.last_seen)}
                  </p>

                </div>
                <p className="absolute w-100 text-[10px] flex ">
                  Created {lastseenDateCount(post?.post_create)}
                  {/* Created {post?.post_create}  */}
                  {/* <a
                    href={post?.post_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ExternalLinkIcon className="w-[14px] ml-1" />
                  </a> */}
                </p>
              </div>
            </div>

            {/* <img src={Facebook} width={26} /> */}
            {/* <div className="absolute right-2 bottom-[-10px] flex gap-1">
              {post?.system == 'android' ?  <img src={Android} width={20} /> :  <img src={Web} width={20} />}
            </div> */}
            {user?.role?.includes("admin") && (
              <div className="grid grid-cols-2 items-center gap-[3px] min-w-[50px] ">
                <AddToStoreBtn
                  post={post}
                  product_model={"posts"}
                  store={store}
                  onDeleteSuccess={onDeleteSuccess}
                />
                <div
                  className="cursor-pointer w-6 text-gray-400 hover:text-gray-700"
                  onClick={() => setShowModal(true)}
                >
                  <XIcon className="w-6" aria-hidden="true" />
                </div>
                <div className="cursor-pointer" onClick={() => addtoStaff()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`w-6 h-6 ${isStaff || post.is_fetured ? "text-green-400" : ""
                      }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    />
                  </svg>
                </div>
                <AddToDraftBtn
                  post={post}
                  myDraftList={myDraftList}
                  setMyDraftList={setMyDraftList}
                />
              </div>
            )}
          </div>
          {location.pathname !== "/draft" && (
            <div
              className={`absolute  ${post?.social_type === "facebook_library" ? "top-[140px]" : "top-[130px]"}  z-20 right-3 ${!play && "hidden"
                }`}
              onMouseEnter={() => {
                setPlay(true);
              }}
            >
              <AddToFavoriteBtn
                post={post}
                myList={myList}
                setMyList={setMyList}
              />
            </div>
          )}
          {post?.resource?.video && (
            <div className={`absolute ${post?.social_type === "facebook_library" ? "top-[140px]" : "top-[130px]"}  left-1 z-20 `}>
              {!pip ? (
                <button
                  onClick={() => {
                    setControls(true);
                    setMuted(false);
                    setIsVideoPlaying(true);
                    setPip(true);
                    setHide(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-7 h-7 text-[#fff] drop-shadow-md"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              ) : (
                <button
                  onClick={() => {
                    setIsVideoPlaying(false);
                    setPip(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-7 h-7 text-[#fff] drop-shadow-md"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
            </div>
          )}
          <div className="border-[2px] p-[3px] rounded-md  border-[#E2E8F0] mb-6">
            {post?.social_type === "instagram" ? (
              <div
                className="flex  items-center "
                onMouseEnter={() => {
                  setSocialHover(true);
                }}
                onMouseLeave={() => setSocialHover(false)}
              >


                <img src={Instagram} width={20} alt="" />


              </div>
            ) : post?.social_type === "tiktok" ? (
              <div
                className="flex items-center "
              // onMouseEnter={() => {
              //   setSocialHover(true);
              // }}
              // onMouseLeave={() => setSocialHover(false)}
              >


                <img src={Tiktok} width={20} alt="" />


              </div>
            ) : post?.social_type === "pinterest" ? (
              <div
                className="flex  items-center "
              // onMouseEnter={() => {
              //   setSocialHover(true);
              // }}
              // onMouseLeave={() => setSocialHover(false)}
              >
                <img src={Pinterest} width={20} alt="" />


              </div>
            ) : (
              <div
                className="flex  items-center "
                onMouseEnter={() => {
                  setSocialHover(true);
                }}
                onMouseLeave={() => setSocialHover(false)}
              >
                <img src={Facebook} width={20} alt="" />


              </div>
            )}
          </div>
        </div>

        {showModal && (
          <Modal
            title="Remove ads"
            message="Choose wether you want to only remove this ad or all this page ads, this action is not reversible."
            show={showModal}
            setShowModal={setShowModal}
            actions={
              <Fragment>
                <button
                  type="button"
                  className="px-8 py-2 shadow-sm text-sm font-medium rounded-md  bg-[#ff0000] hover:opacity-90 text-white focus:outline-none focus:ring-red-500"
                  onClick={() => {
                    removeFromDb(true, post._id);
                  }}
                >
                  {deleting ? <LoadingSpinner /> : "Remove post"}
                </button>
                <button
                  type="button"
                  className="px-8 py-2 shadow-sm text-sm font-medium rounded-md  bg-[#ff0000] hover:opacity-90 text-white focus:outline-none focus:ring-red-500"
                  onClick={() => {
                    removeFromDb("page_id", post._id);
                  }}
                >
                  {deleting ? <LoadingSpinner /> : "Remove all"}
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-200 bg-gray-100 px-4 py-2 text-sm font-medium text-black hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-300 focus-visible:ring-offset-2"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </Fragment>
            }
          />
        )}
      </div>
    );
  };

  const Actions = () => {
    let download = !post?.resource?.video ? s3_bucket + "/" + post?.resource?.image :
      (post?.social_type !== undefined
        ? `${s3_bucket + "/" + post?.resource?.video}`
        : s3_bucket + post?.resource?.video)

    return (
      <div className="w-full flex  items-center gap-2 p-3 ">
        <button
          type="button"
          className="w-full flex items-center justify-center 
          px-4 py-2  shadow-sm text-sm font-medium 
          rounded-3xl
          bg-[#ff6c3d] hover:opacity-90 text-white focus:outline-none "
          onClick={ShowAnalytics}
        >
          <ChartBarIcon className="w-5 mr-2" />
          View Analytics
        </button>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href={download}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
            <path fillRule="evenodd" d="M12 2.25a.75.75 0 0 1 .75.75v11.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V3a.75.75 0 0 1 .75-.75Zm-9 13.5a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
          </svg>

        </a>
      </div>
    );
  };

  return (
    <div className="relative">
      <Header />

      <Description Description={post?.Ad_Description} id={post?._id} />

      <div
        className={`w-full `}
        onMouseEnter={() => {
          setPlay(true);
        }}
        onMouseLeave={() => {
          setPlay(false);
        }}
      // onMouseLeave={() => setPlaying(false)}
      >
        {post?.resource?.video ? (
          <div>
            <div
              style={{
                backgroundImage: `url("${s3_bucket + "/" + post?.resource?.image
                  }")`,
              }}
              ref={divRef}
              className={`rlative cursor-pointer flex justify-center bg-center bg-cover bg-no-repeat`}
              onClick={handleClick}
            >
              <div
                className={`relative cursor-pointer flex justify-center w-full max-w-max h-full`}
                onMouseEnter={() => {
                  setHide(true);

                  if (!pip && !isVideoPlaying) {
                    setControls(false);
                    setMuted(true);
                    setIsVideoPlaying(true);
                  } else if (pip) {
                    setControls(true);
                  }
                }}
                onMouseLeave={() => {
                  setControls(false);
                  setHide(false);
                  if (!pip) {
                    setMuted(false);
                    setIsVideoPlaying(false);
                  }
                }}
              >
                <ReactPlayer
                  className=" object-cover transition-opacity duration-150 "
                  url={
                    post?.social_type !== undefined
                      ? `${s3_bucket + "/" + post?.resource?.video}`
                      : s3_bucket + post?.resource?.video
                  }
                  // width={'auto'}
                  // light
                  // light={playing === true ? false : (
                  style={{
                    backgroundColor:
                      isVideoPlaying && "hsla(0, 3%, 72%, 0.832)",
                    // dropFilter: isVideoPlaying && "blur(10px)"
                  }}
                  light={
                    !isVideoPlaying
                    //   ? false :  (
                    //   <div >
                    //     <div
                    //       style={{
                    //         backgroundImage: `url("${s3_bucket + '/' + post?.resource?.image}")`,
                    //       }}
                    //       className={`absolute w-full h-full top-0 left-0  bg-[url('https://www.w3schools.com/howto/photographer.jpg')] blur-[4px] bg-center bg-cover bg-no-repeat `}
                    // 		/>
                    //     <img className="absolute top-1/2 left-1/2 [transform:translate(-50%,-50%)] blur-[4px] " src={`${s3_bucket + '/' + post?.resource?.image}`} alt='Thumbnail' />
                    //   </div>
                    // )
                  }
                  playIcon={
                    <div
                      className={`absolute min-w-[100px] w-full h-full flex items-center justify-center `}
                    >
                      {/* <FaPlay size={55} color={'white'} className={'outline outline-[5px] rounded-md b p-4'} /> */}
                    </div>
                  }
                  playing={isVideoPlaying}
                  muted={muted}
                  controls={controls}
                />
              </div>

              {/* <button className="absolute  backdrop-contrast-150  top-0  w-full h-full flex items-center justify-center"
            onClick={()=>{
          
            }}>
              <PlayIcon width={80} color={'white'} className={''} />
            </button> */}
            </div>
          </div>
        ) : (
          <img
            className="flex-shrink-0 object-contain h-[360px] mx-auto bg-slate-200"
            // src={`https://aas-bucket.s3.amazonaws.com/uploads/${post?.resource?.image}`}
            src={`${s3_bucket + "/" + post?.resource?.image}`}
            style={{ objectFit: "cover" }}
            alt=""
          />
        )}
      </div>
      <div className="w-full h-[50px] px-3 flex gap-2 items-center justify-between bg-gray-100 ">
        <div>
          <p className="text-[#3C4344] text-[14px] font-bold line-clamp-1 break-all">
            {post?.title}
          </p>
          {/* <p className="mt-1 text-gray-500 text-sm">{ }</p> */}
        </div>
        {
          !(post.spons === "trending" && !post.landing_page) &&

          <a
            target="_blank"
            rel="noopener noreferrer"

            // onMouseEnter={()=>{
            //   console.log(post)
            // }}
            href={post?.landing_page || post?.landanig_page || ((!post?.page_link.includes('https://') && 'https://') + post?.page_link)}
            className={`inline-flex min-w-fit items-center px-3 py-[5px] border-[1.5px] border-[#ff6c3d] bg-[#ffedd5] Rshadow-sm text-sm rounded-lg text-[#ff6c3d] hover:text-white hover:bg-[#ff6c3d] ${user?.role?.includes("admin") ? "" : "cursor-not-allowed pointer-events-none"} `}
          >
            {post?.button?.split("_").join(" ") || "View Page"}
          </a>
        }
      </div>
      {post?.social_type !== "facebook_library" && (
        <PostStats post={post} pip={pip} hide={hide} />
      )}
      <Actions />
      {viewAnalytics && (
        <Analytics
          viewAnalytics={viewAnalytics}
          setViewAnalytics={setViewAnalytics}
          ID={ID}
          prevPost={SeeprevPost}
          nextPost={SeenextPost}
          userInfo={userInfo}
        />
      )}
      {deleteMessage && deleteMessage.index === index && (
        <Toast setClose={setDeleteMessage} data={deleteMessage} />
      )}
    </div>
  );
}
